.amenities-chip {
    color: $color-black;
    background-color: $color-champagne-pink;
    font-weight: 500;
    font-size: 14px;
    line-height: rem(15);
    letter-spacing: 0.4px;
    // border: rem(1) solid $color-dark-grey;
    border-radius: rem(20);
    padding: rem(7) rem(10);
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;
    margin-top: rem(10);
    animation: appear .15s ease-in-out forwards;
    cursor: pointer;
    width: max-content;
    // &:hover {
    //     background-color: $color-field-grey;
    // }
    &:not(:last-child) {
        margin-right: rem(10);
    }

    .amenities-chip_close-btn {
        cursor: pointer;
        outline: none;
        border: none;
        background: none;
        margin-left: rem(10);
        transform: translateY(-10%);
        path {
            stroke-width: rem(1.25);
            stroke: $color-black;
        }
        // &:hover {
        //     path {
        //         stroke: $color-black;
        //     }
        // }
    }
}