//Parent wrapper
.cn__geopages__wrapper{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    .cn__hp__omnisearch{
        place-content: center;
        .omnisearch_content {
            margin: 1.5rem auto;
        }
    }
    //Header and Footer
    .header,.footer{
        .max-width-wrapper{
            max-width: 1240px;
        }
    }

    //Header
    .header {
        .header-logo .logo-purple, .header-logo .logo-pink{
            fill: #fff!important;
        } 
        .header-nav {
            .li--blog, .li--help {
                display: none;
            }
            > ul li a svg * {
                stroke: #fff;
              }
        }
    }

    //Values
    .cn__values{
        position: absolute;
        bottom: 52px;
        left: 50%;
        transform: translateX(-50%);
        max-width: 1140px;
        @media (orientation: landscape)and (max-width: 950px) {
            bottom: 3px;
            .cn__values__value__inner__title{
                font-size: 12px;
            }
            .cn__values__value__inner__description{
                font-size: 10px;
            }
            .cn__values__value__inner{
                svg{
                    width: 50px;
                    height: 50px;
                }
            }
        }
    }

    //Footer
    .cn__shortfooter__wrapper {
        .cn__shortfooter {
            max-width: 1200px;
            padding: 30px 30px 30px;
        }
    }

    .cn__geopages__end{
        //background: linear-gradient(180deg, #FFFFFF 0%, #EBF3F8 39.77%, #EEF2EF 100%);
        background: #FFFFFF;
        margin-top: -100px;
        padding-top: 100px;

        .subscribe-section, .cn__hp__featured, .cn__contentlocation__wrapper{
            margin: 0 auto;
            max-width: 1140px;
        }

        //Blog section
        .cn__hp__featured{
            margin: 0 auto 100px;
            padding: 0;
        }
    }

    //Cookie banner
    .cn__cookie-wrapper{
     
        .cn__cookie{
            box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12);
            outline: 1px solid #DFE2E5;
            @media screen  and (max-width: 600px) {
            height: 290px;
            }
        }
    }

    //Faqs
    .cn__faq{
        background: none;
    }

}
//Geo Pages content section
.cn__geopages{
    position: relative;
    width: 100%;
    padding: 0;
    background-color: #FFFFFF;

    &__inside{
        max-width: 1140px;
        margin: auto;
        padding-top: 100px;
    }

    .show-more {
        font-family: $primary-font;
        background: none;
        border: none;
        outline: 0;
        cursor: pointer;
    }

    &__heading{
        &__title{
            max-width: calc(100% - 86px);
            font-weight: 700;
            font-size: 22px;
            letter-spacing: -0.01em;
            line-height: 1.2;
            color: #000000;

        }
        &__subtitle{
            max-width: calc(100% - 86px);
            font-size: 18px;
            line-height: 1.2;
            color: #000000;
            margin-top: 10px;
        }
    }

    .simplebar-content-wrapper, .slidermobile__wrapper{
        scroll-behavior: smooth;
        &::-webkit-scrollbar {
            display: none;
        }
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .simplebar-track{
        display: none;
    }

    &__dynamic{
        position: relative;
        width: 100%;
        max-width: 1140px;
        margin: 0 auto 100px;

        &__section{
            position: relative;
            width: 100%;
            margin: 100px auto;
            --min-height1: 'auto';
            --min-height2: 'auto';

            .cn__slider{
                margin-top: 25px;
            }
        }
    }
}

@media screen and (max-width:1136px){
    .cn__geopages__wrapper{

        .omnisearch{
            // display: grid;
            // place-items: center;
            padding: 0 30px;
        }

        .cn__geopages__end{
            .cn__hp__featured{
                margin: 0 auto 80px;
            }
            .subscribe-section{
                padding: 0 30px;
            }
            .cn__faq{
                margin: 80px auto;
                padding: 0;
            }
        }
    }

    //Geo Pages content section
    .cn__geopages{
        padding: 0;

        &__heading{
            padding: 0 30px;
            &__title, &__subtitle{
                max-width: 100%;
            }
        }

        &__dynamic{
            margin: 0 auto 80px;

            &__section{
                margin: 80px auto;

                .cn__slider{
                    margin-top: 25px;

                    .simplebar-track{
                        display: none;
                    }
                }
            }
        }

        &__inside{
            padding-top: 80px;
        }
    }
}

@media screen and (max-width:600px){
    .cn__geopages__wrapper{

        //Banner
        .banner-search{
            position: relative;
            display: table;
            width: 100%;
            padding-top: 80px;
            //padding-bottom: 60px;
            .cn__gp__search__image{
                position: absolute;
                top: 0;
                width: 100%;
                height: 64.5%;
                object-fit: cover;
                filter: brightness(0.7);
            }
        }

        //Header
        .header{
            .logo-birds_icon{
                path{
                    fill: $color-white;
                }
            }
            .hamburguer-menu {
                .hamburguer-menu-lin1,.hamburguer-menu-lin2, .hamburguer-menu-lin3{
                    background: #ffffff !important
                }
            }
            .hamburguer-menu-inner{
                &.hm-opened {
                    .hamburguer-menu-lin1,
                    .hamburguer-menu-lin2,
                    .hamburguer-menu-lin3 {
                        background: $color-black !important;
                    }
                }
            }
        }

         //Footer
        .cn__shortfooter__wrapper {
            .cn__shortfooter {
                padding: 20px 20px 30px;
            }
        }

        //Mobile Header
        .geopage-header-mobile{
            position: absolute !important;
            background: transparent !important;
        }
        .geopage-header-mobile2{
            .cnbirds{
                .logo-purple{fill: #4c35bc;}
                .logo-pink{fill: #fa368b}
            }
            .hamburguer-menu{
                .hamburguer-menu-lin1,.hamburguer-menu-lin2{
                    background: #242526 !important;
                }
            }
        }
        .geopage-header-mobile3{
            position: absolute !important;
            background: transparent !important;
        }

        .cn__geopages__end{

            .subscribe-section{
                padding: 0 20px;
            }

            //Blog section
            .cn__hp__featured{
                margin: 0 auto 60px;
            }

            //faq
            .cn__faq{
                padding: 0;
            }
        }
    }
    //Geo Pages content section
    .cn__geopages{
        margin-top: 0px;
        padding: 0;

        &__heading{
            padding: 0 20px;
        }

        &__dynamic{
            margin: 0 auto 60px;

            &__section{
                margin: 60px auto;
            }
        }
    }
}
