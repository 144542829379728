@import
'./side-bar',
'./mobile',
'./tablet',
'./desktop';

.search-results-page_host_view {
    .property-card {
        .property-card_wishlist-marker {
            display: none;
        }
    }
}

.poi-suggestion-pin-map,
.portal-map-control {
    // Jesus Christ please forgive me, this is a hack
    position: static !important;
    width: min-content;
}

.portal-map-expand-control {
    width: min-content;
    left: 50% !important;
    top: unset !important;
    transform: translateX(-50%);
    bottom: 0;
    height: 0;
}

@include searchResultsDesktopDefault;

.searched-listing-card_unavailability-message {
    display: flex;
    align-items: center;
    column-gap: 10px;
    .searched-listing-card_unavailability-message-date {
        text-align: left;
    }
    padding: 10px;
    color: var(--color-red);
    font-size: 10px;
    line-height: 150%;
    border-radius: 14px;
    border: 1px solid var(--color-red);
    width: fit-content;// 174px;
}

.main {
    animation: appear .5s ease-in-out forwards;
    opacity: 0;
    position: relative;
    background: $color-white;
    z-index: 1;

    .no-properties, .error-message {
        animation: appear .3s ease-in-out;
        margin-top: rem(70);
        display: flex;
        justify-content: center;
        align-items: flex-start;
        .no-properties_wrapper, .error-message_wrapper {
            max-width: rem(730);
            text-align: center;
            font-family: $primary-font;
            .no_properties_continer {
                .no_properties_continer_title {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 150%;
                    color: $color-pure-black;
                    margin-bottom: 20px;
                }
                .no_properties_continer_sub_title {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 150%;
                    color: $color-pure-black;
                    margin-bottom: 30px;
                }
                .no_properties_continer_chip {
                    display: flex;
                    flex-wrap: wrap;
                    column-gap: 10px;
                    row-gap: 10px;
                    justify-content: center;
                    .no_properties_chip {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        padding: 8px 16px;
                        gap: 15px;
                        height: 40px;
                        background: $color-champagne-pink;
                        border-radius: 30px;
                        .no_properties_chip_title {
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 150%;
                            letter-spacing: 0.01em;
                            color: $color-pure-black;
                            @media screen and (max-width: 600px) {
                                font-size: 12px;
                            }
                        }
                        .no_properties_chip_closeicon{
                            cursor: pointer;
                            svg {
                                path {
                                    stroke-width: 2px;
                                }
                            }
                        }
                    }

                }
            }
            .no-properties_header, .error-message_header {
                font-weight: 500;
                font-size: rem(16);
            }
            .no-properties_message, .error-message_message {
                margin-top: rem(13);
                font-size: rem(16);
            }
        }
    }

    .header.header-transparent {
        &.header-mob {
            @media screen and (max-width: 600px)  {
                .menu-mob {
                    grid-column: 2 / 4;
                }
            }
        }

        position: initial;
        padding: 15px 0;
        .header-grid {
            @media screen and (max-width: 1136px) and (min-width: 601px) {
                .header-nav {
                    grid-column: 5 / 17;
                }
            }
            @media screen and (max-width: 1919px) and (min-width: 1136px) {
                grid-template-columns: 30px repeat(7, 1fr 10px) 1fr 50px;
            }
            @media (min-width: 1919px) {
                grid-template-columns: 30px repeat(7, 1fr 10px) 1fr 50px;
            }
        }
    }

    .filters-tab-bar {
        .filters-tab-bar_primary {
            margin-top: rem(25);
            display: grid;
            // grid-template-columns: 50% repeat(2, 25%);
            @include search;

            .filters-date-picker {
                cursor: pointer;
                .DateRangePickerInput {
                    grid-template-columns: 1fr auto 1fr;
                }
                &.date-picker__selected {
                    width: rem(170);
                    .DateInput:last-child {
                        margin-right: 0;
                    }
                }
                width: rem(190);
                .DateInput {
                    width: rem(75);
                    &:first-child {
                        margin-right: rem(10);
                    }
                    &:last-child {
                        margin-left: rem(10);
                    }
                }
            }

            .guest-selector {
                white-space: nowrap;
            }
        }
        @include filtersTabbarSecondary;

        @include filtersTabbarAmenities;

        .filters-tab-bar_extended {
            animation: appear .3s ease-in-out forwards;
            .filters-tab-bar_amenities {
                .amenities-collection {
                    padding-bottom: rem(40);
                    border-bottom: rem(1) solid $color-light-gray-input;
                    .amenities-chip {
                        margin-top: 0;
                    }
                }
            }
        }
    }



    .main_layout {
        .scrollable-property-container {
            @media (min-width: 600px) and (max-width: 1100px) {
                .searched-listing {
                    padding: rem(20);
                    padding-bottom: 0;
                }
            }
            .searched-listing-card {
                .property-card {
                    .property-card__imginfo {
                        @media (min-width: 600px) {
                            width: 29.764%;
                        }
                    }
                }
                &.searched-listing-card--available {
                    a {
                        border-color: #6fcf97;
                        border-style: solid;
                        border-width: 4px;
                        border-top-left-radius: 23px;
                        border-top-right-radius: 22px;
                        border-bottom-left-radius: 24px;
                    }
                }
                &.searched-listing-card--unavailable {
                    .property-card {
                        margin-bottom: initial;
                        .property-card_slider-container, .property-card_image-slider {
                            .swiper-container {
                                border-bottom-left-radius: 0px;
                            }
                        }
                    }
                }
            }
            // padding-top: rem(320);
            position: relative;
            .no-properties {
                margin-top: rem(35);
                margin-bottom: rem(70);
            }

            .scrollable-property-container__loader {
                // height: calc(100vh - 500px);
                padding-top: rem(20);
                padding-left: rem(20);
                padding-right: rem(20);
            }
            &.scrollable-property-container__exists {
                .scrollable-property-container_wrapper  {
                    padding-left: rem(20);
                    padding-right: rem(20);
                    &.scrollable-property-container_wrapper--first-chunk {
                        padding-top: rem(20);
                    }
                    .collection-footer {
                        grid-column: 1 / -1;
                        grid-row: 2;
                    }
                }
            }
        }
        .search-results-paginator {
            margin-top: rem(30);
            margin-bottom: rem(30);
            display: flex;
            justify-content: center;
            .MuiPaginationItem-root {
                min-width: rem(36);
                // width: rem(36);
                height: rem(36);
                font-family: $primary-font;
                font-size: 1rem;
                font-weight: 500;
                border-radius: rem(15);
                color: $color-black;
                &.Mui-selected {
                    background-color: $color-black;
                    color: $color-subtle-white;
                }
            }
        }
        .aside-property-detail {
            display: none;
            margin-left: initial;
            margin-top: rem(15);
        }
    }

    @include commonMixin;
    @include mobileMixin;
    @include tabletMixin;
    @include desktopMixin;
}

.guest-selector_popover-root {
    .guest-selector_popover {
        max-height: calc(100vh - 235px);
        overflow-y: auto;
    }
}

.drop-down-popover_popover-root {
    position: absolute;
    z-index: 2;
    margin-top: rem(5);
    .inner-wrapper-layout {
        &.inner-wrapper-layout__short {
            //max-width: rem(250);
            width: auto;
        }
        &.inner-wrapper-layout__max-content {
            max-width: 290px;
            .sort-inner_name {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
        background-color: $color-white;
        box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12), 0px 10px 32px -4px rgba(24, 39, 75, 0.1);
        // background: $color-light-gray-shade;
        width: rem(326);
        border-radius: rem(10);
        border: rem(1) solid $color-light-gray-input;
        // box-shadow: 0 rem(2) rem(10) rgba(0, 0, 0, .15);
        padding: rem(20);

        max-height: calc(100vh - 235px);
        overflow-y: auto;
        &.budget-popup-inner {
            width: rem(350);
            padding: 0;
            overflow-y: initial;
        }
        &.inner-wrapper_popover {
            opacity: 0;
            transition: .3s ease-in-out;
            &.inner-wrapper_popover__hide {
                opacity: 0;
            }

            &.inner-wrapper_popover__show {
                opacity: 1;
            }
        }

        .action-bar {
            margin: 0;
            padding-bottom: 0;
        }
    }
    @include innerWrapperLayoutContentMixin;
    .inner-wrapper-layout_content-area {
        // padding-bottom: rem(25);
        .budget-inner {
            // margin-bottom: rem(10);
            .price-widget {
                .price-widget_inputs {
                    margin-bottom: 0;
                }
            }
        }
        .star-rating-inner {
            .checkbox:first-child {
                .checkbox_label {
                    padding-top: rem(10);
                }
            }
        }
        .recommended-inner, .views-inner {
            &.show-more-less {
                @media (min-width: 1010px) {
                    min-width: initial;
                }
                .show-more-less_wrapper {
                    display: initial;
                }
                .show-more-less_action-container {
                    padding: initial;
                    padding-top: rem(20);
                }
            }
        }
    }

    .radio {
        border-radius: rem(18);
        padding-left: rem(15);
        padding-right: rem(15);
        font-size: rem(14);
        &.radio--checked {
            background-color: $color-champagne-pink;
        }
    }

    .checkbox {
        font-size: rem(14);
        font-weight: 400;
        border-radius: rem(18);
        &:not(:last-child) {
            margin-bottom: rem(4);
        }
    }

    .inner-wrapper-sort-selector {
        .inner-wrapper-layout_content-area {
            .radio {
                font-size: rem(14);
                font-weight: 400;
                border-radius: rem(18);
                padding: rem(8) rem(16);
                &.radio--checked {
                    .radio_label {
                        .sort-inner_icon {
                            display: initial;
                        }
                    }
                }
                &:not(:last-child) {
                    margin-bottom: rem(4);
                }
                & > input {
                    display: none;
                }

                .radio_label {
                    width: 100%;
                    display: flex;
                    column-gap: rem(10);
                    align-items: center;
                    .sort-inner_icon {
                        display: none;
                        path {
                            stroke: $color-black;
                        }
                    }
                }
            }
        }
    }
}
body.no-scroll {
    @media(max-width:600px) {
        overflow: hidden;
    }
}
body.search-results-page {
    .cn__save-wishlist-wrapper{
        z-index: 1202;
    }
    background-color: $color-white;
    &.auth-dialog-open {
        .search-results {
            .header-wrapper {
                .header {
                    z-index: 3 !important;
                }
            }
            .mobile-tab-action {
                z-index: 0;
            }
        }
    }
}

.bathrooms-and-beds-type-inner {
    display: grid;
    gap: rem(10);

    .bathrooms-and-beds-type-inner__section {
        display: flex;
        flex-direction: column;
        // align-items: center;
    }

    .bathrooms-and-beds-type-inner__section-header {
        margin-top: rem(7);
        color: $color-pure-black;
        font-size: 14px;
        font-weight: 600;
    }

    .bathrooms-and-beds-type-inner__section-checkboxes {
        display: flex;
        flex-wrap: wrap;
        .bathrooms-and-beds-type-inner__section-checkboxes-text {
            display: flex;
            align-items: center;
            padding-right: 5px;
        }
        .checkbox {
            &:not(:last-child) {
                margin-bottom: 0;
            }
            .checkbox_label {
                .checkbox_label-text {
                    display: block;
                    text-align: center;
                }
            }
        }
    }
}

.guest-selector_modal, .more-filters_modal, .filters-map_modal, .mobile-filter_modal {
    .guest-selector_modal-overlay, .more-filters_modal-overlay, .filters-map_modal-overlay, .mobile-filter_modal-overlay {
        background-color: rgba(0, 0, 0, .5);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 3;
        outline: none;
        .guest-selector_modal-content, .more-filters_modal-content, .mobile-filter_modal-content {
            outline: none;
            height: 100%;
            display: flex;
            overflow-y: auto;
            .mobile-modal-header {
                background-color: $color-white;
                position: relative;
                .modal-header_wrapper {
                    padding-top: rem(25);
                    padding-bottom: rem(20);
                    .modal-header_close {
                        background: none;
                        border: none;
                        position: absolute;
                        outline: none;
                        left: rem(20);
                        top: 50%;
                        transform: translateY(-50%);
                        cursor: pointer;
                        .carret-icon {
                            transform: rotate(90deg);
                            width: rem(18);
                            height: rem(18);
                        }
                    }
                    .modal-header_content {
                        font-size: $primary-font;
                        color: $color-black;
                        font-size: rem(22);
                        font-weight: 500;
                        text-align: center;
                    }
                }
            }
            .inner-wrapper-layout {
                margin: auto;
                .inner-wrapper-layout_content-area {
                    position: relative;
                    border-top-left-radius: rem(6);
                    border-top-right-radius: rem(6);
                    .modal_close-btn {
                        background: none;
                        border: none;
                        outline: none;
                        position: absolute;
                        left: rem(30);
                        top: rem(20);
                        cursor: pointer;
                        width: rem(30);
                        height: rem(30);
                        border-radius: 50%;
                        transition: background-color .3s ease-in-out;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        &:hover {
                            background-color: $color-light-gray-input;
                        }
                        .modal_close-btn-icon {
                            width: rem(15);
                            height: rem(15);
                        }
                    }
                    background-color: $color-subtle-white;
                    .more-inner, .show-more-less {
                        min-width: rem(600);
                        @media (min-width: 1010px) {
                            min-width: rem(800);
                        }
                        .more_title {
                            font-size: rem(22);
                            font-weight: 700;
                            padding: rem(25) rem(25) rem(15);
                            border-bottom: rem(1) solid $color-light-gray-input;
                            text-align: center;
                        }
                        .more_wrapper, .show-more-less_wrapper {
                            padding-top: rem(20);
                            padding-left: rem(20);
                            padding-right: rem(20);
                            padding-bottom: rem(20);
                            overflow: auto;
                            max-height: calc(100vh - 300px);
                            .more_separator {
                                margin-top: rem(40);
                                margin-bottom: rem(40);
                            }
                            .more_services {
                                .services-columns__ungrouped {
                                    margin-top: rem(10);
                                }
                                .services-inner {
                                    .services-columns {
                                        .services-inner_category {
                                            margin-bottom: rem(20);
                                        }
                                    }
                                }
                                &.more_accommodation, &.more_amenities, &.more_cancellation-policies {
                                    // border-top: rem(1) solid $color-light-gray-input;
                                    // padding-top: rem(25);
                                    .services-columns {
                                        margin-top: rem(20);
                                    }
                                    .accommodation-type_checkbox {
                                        .checkbox_label {
                                            align-items: flex-start;
                                            .accommodation-type_label-secondary {
                                                color: $color-gray-font;
                                                font-size: .7rem;
                                            }
                                        }
                                    }
                                    .cancellation-policy_checkbox {
                                        .cancellation-policy_more-info-icon {
                                            margin-left: rem(10);
                                        }
                                    }
                                }
                                &.more_cancellation-policies {
                                    padding-bottom: rem(20);
                                    border-bottom: rem(1) solid $color-light-gray-input;
                                }
                                margin-top: initial;// rem(25);
                                padding-bottom: rem(25);
                                .more_services-title {
                                    font-size: rem(18);
                                    font-weight: 500;
                                    text-transform: uppercase;
                                }
                                .services-columns, .amenities-inner, .accommodation-type-inner, .brand-name-type-inner {
                                    display: grid;
                                    grid-template-columns: repeat(auto-fill, 50%);
                                    .category {
                                        .category_title {
                                            font-size: 1rem;
                                            margin-top: rem(20);
                                            margin-bottom: rem(20);
                                        }
                                    }
                                }
                                .services-columns {
                                    display: block;
                                    column-count: 2;
                                }
                                .amenities-inner, .accommodation-type-inner, .brand-name-type-inner {
                                    margin-top: rem(20);
                                }
                            }
                        }
                    }

                    .more-inner {
                        .more_wrapper {
                            .more_wrapper_header {
                                font-weight: bold;
                                font-size: rem(18);
                                margin-bottom: rem(20);
                            }
                            .more_wrapper_amenities {
                                .show-more-less_wrapper {
                                    overflow: initial;
                                    max-height: initial;
                                    padding: initial;
                                }
                                .show-more-less_action-container {
                                    padding-left: initial;
                                }
                            }
                            // padding: initial;
                            // overflow: initial;
                            // max-height: initial;
                            // .more_services {
                            //     .show-more-less_wrapper {
                            //         display: block;
                            //     }
                            //     .show-more-less_action-container {
                            //         padding-bottom: initial;
                            //     }
                            // }
                        }
                    }

                    .neighbourhood-inner_loader {
                        margin-right: rem(30);
                        margin-bottom: rem(20);
                        display: grid;
                        grid-template-columns: max-content 1fr;
                        column-gap: rem(15);
                        .neighbourhood-inner_loader-particle {
                            &:last-child {
                                width: 100%;
                            }
                        }
                    }
                }
                .action-bar {
                    background-color: $color-white;
                    border: none;
                    padding: 0 rem(20) rem(20);
                    margin: 0;
                    border-bottom-right-radius: rem(6);
                    border-bottom-left-radius: rem(6);
                }
            }
            .guest-selector_popover {
                opacity: 1;
                overflow-y: auto;
                height: 100%;
                width: 100%;
                margin: auto;
                border-radius: initial;
                padding: 0;
                background-color: $color-white;

                .guest-selector_mobile-scroll-wrapper {
                    height: calc(100% - 143px);
                    overflow-y: scroll;
                    padding-left: rem(20);
                    padding-right: rem(20);
                    .child-manager {
                        .child-manager_action {
                            transform: none;
                        }
                    }
                }
                .guest-selector_action-bar {
                    border-radius: initial;
                    position: relative;
                    padding-bottom: rem(20);
                    padding-top: rem(20);
                    border-top: 0.0625rem solid var(--color-light-grey);
                    // box-shadow: 0 rem(4) rem(15) rgba(0, 0, 0, .15);
                    margin: 0;
                    .action-bar_btn {
                        transform: none;
                    }
                }
            }
        }
    }
}

.more-filters_modal--progressive {
    .more-filters_modal-content--progressive {
        max-width: 85rem;
        width: 100%;
        .inner-wrapper-layout {
            .inner-wrapper-layout_content-area {
                overflow: auto;
                max-height: calc(100vh - 219px);
                .filters-progressive-wrapper {
                    background-color: $color-subtle-white;
                    padding-top: rem(20);
                    padding-bottom: rem(30);
                    padding-left: rem(30);
                    padding-right: rem(30);
                    box-shadow: inset 0 rem(6) rem(12) rem(-6) rgba(24, 39, 75, .06);
                    .category-separator {
                        border-top: rem(1) solid $color-light-gray-input;
                    }
                    .category {
                        &:not(:first-child) {
                            margin-top: rem(20);
                        }
                        .category_title {
                            font-size: rem(18);
                            font-weight: 600;
                        }
                        .category_wrapper {
                            margin-top: rem(15);
                            display: grid;
                            align-items: center;
                            grid-template-columns: repeat(2, 1fr);
                            @media (min-width: 900px) {
                                grid-template-columns: repeat(3, 1fr);
                            }
                            @media (min-width: 1440px) {
                                grid-template-columns: repeat(4, 1fr);
                            }
                            column-gap: rem(10);
                            row-gap: rem(4);
                            .checkbox {
                                display: flex;
                                font-size: rem(14);
                                font-weight: 400;
                                border-radius: rem(18);
                                // padding-left: rem(10);
                                // padding-right: rem(10);
                                height: min-content;
                                &:not(:last-child) {
                                    margin-bottom: rem(4);
                                }
                                &:hover {
                                    background: $color-Shade-gray;
                                }
                                &.checkbox--checked {
                                    background-color: $color-champagne-pink;
                                }
                                .checkbox_label {
                                    width: 100%;
                                    padding-left: rem(15);
                                    padding-right: rem(15);

                                    .checkbox_label-text {
                                        display: grid;
                                        width: 100%;
                                        grid-template-columns: 1fr auto;
                                        .checkbox_label-text_count {
                                            margin-left: rem(10);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.show-more-less {
    @media (min-width: 1010px) {
        min-width: rem(760);
    }

    border-top-left-radius: initial;
    border-top-right-radius: initial;
    border-bottom-left-radius: rem(15);
    border-bottom-right-radius: rem(15);

    &.show-more-less--no-control {
        .show-more-less_wrapper {
            padding-bottom: rem(20);
        }
    }


    .show-more-less_action-container {
        padding: rem(25) rem(30);

        .show-more-less_action-container_show-more-btn {
            border: none;
            background: none;
            outline: 0;
            cursor: pointer;
            color: var(--color-custom-blue-light);
            font-family: $primary-font;
            font-weight: 500;
            font-size: 1rem;
            line-height: rem(20);
            display: flex;
            align-items: center;
            &.show-more-less_action-container_show-more-btn--active {
                svg {
                    transform: rotate(-180deg);
                }
            }
            svg {
                transition: .3s ease-in-out;
                transform: rotate(-90deg);
                margin-left: rem(10);

                path {
                    stroke: var(--color-custom-blue-light);
                }
            }
        }
    }
}

.more-filters_modal {
    &.more-filters_modal--progressive {
        &.more-filters_modal--centered {
            .more-filters_modal-overlay--progressive {
                align-items: center;
            }
        }
        .more-filters_modal-overlay--progressive {
            .more-filters_modal-content--progressive {
                .inner-wrapper-layout {
                    .modal-header--progressive {
                        .modal-header_wrapper {
                            .swiper-container {
                                max-width: calc(100vw - 367px);
                                @media (min-width: 1440px) {
                                    max-width: 75%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.beds-and-bathrooms {
        .more-filters_modal-content {
            .inner-wrapper-layout {
                .inner-wrapper-layout_content-area {
                    .more-inner {
                        min-width: rem(400);
                    }
                }
            }
        }
    }
    .more-filters_modal-overlay {
        display: flex;
        // align-items: center;
        justify-content: center;
        padding-top: rem(40);
        padding-bottom: rem(40);
        padding-left: rem(40);
        padding-right: rem(40);
        .more-filters_modal-content {
            // padding-top: 3vh;
            height: initial;
            display: initial;
            overflow-y: initial;
            .inner-wrapper-layout {
                .modal-header {
                    &.modal-header--progressive {
                        box-shadow: 0 rem(6) rem(12) rem(-6) rgba(24, 39, 75, .06);
                        .modal-header_wrapper {
                            justify-content: flex-start;
                            .modal-header_content {
                                width: auto;
                                padding-top: rem(25);
                                padding-bottom: rem(15);
                                padding-left: rem(25);
                                padding-right: rem(30);
                            }
                            .modal-header_close {
                                .modal_close-btn-icon {
                                    path {
                                        stroke: $color-black;
                                        stroke-linecap: round;
                                    }
                                }
                            }
                        }
                    }
                    border-top-left-radius: rem(15);
                    border-top-right-radius: rem(15);
                    background-color: $color-white;
                    border-bottom: rem(1) solid $color-light-gray-input;
                    .modal-header_wrapper {
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .modal-header_close {
                            background: none;
                            border: none;
                            outline: none;
                            position: absolute;
                            right: rem(30);
                            top: rem(20);
                            cursor: pointer;
                            width: rem(30);
                            height: rem(30);
                            border-radius: 50%;
                            transition: background-color .3s ease-in-out;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            &:hover {
                                background-color: $color-light-gray-input;
                            }
                            .modal_close-btn-icon {
                                width: rem(15);
                                height: rem(15);
                            }
                        }
                        .modal-header_content {
                            width: 100%;
                            padding: rem(25) rem(25) rem(15);
                            // text-align: center;
                            font-size: rem(22);
                            line-height: rem(26);
                            font-weight: 700;
                            .outer_info-container {
                                display: flex;
                                align-items: center;
                                // justify-content: center;
                                @include clearableTag;
                                .clearable-tag {
                                    font-weight: 600;
                                    font-size: 1rem;
                                }
                            }
                        }
                        .modal-header_action-clear {
                            margin-top: rem(10);
                            margin-left: rem(15);
                            background: none;
                            border: none;
                            outline: 0;
                            text-decoration: underline;
                            font-family: $primary-font;
                            color: $color-black;
                            font-size: rem(12);
                            cursor: pointer;
                        }
                        .swiper-container {
                            max-width: rem(500);
                            margin: 0;
                            .amenities-chip {
                                animation: none;
                                font-size: rem(12);
                            }
                        }
                    }

                    .swiper-container.swiper-container-horizontal {
                        .swiper-button-next,
                        .swiper-button-prev {
                            color: $color-black;
                            outline: 0;

                            &::after {
                                margin-top: rem(5);
                                font-weight: bold;
                                font-size: initial;
                                background-color: $color-white;
                                box-shadow: 0 0 rem(20) rem(30) $color-white;
                            }

                            &.swiper-button-disabled {
                                opacity: 0;
                                color: $color-black;
                            }
                        }

                        .swiper-button-prev {
                            transform: translateX(-12px);
                        }

                        .swiper-button-next {
                            transform: translateX(12px);
                        }
                        .swiper-wrapper {
                            .swiper-slide {
                                width: fit-content;
                            }
                        }
                    }
                }
                .inner-wrapper-layout_content-area, .more-inner {
                    border-top-left-radius: initial;
                    border-top-right-radius: initial;
                    border-bottom-left-radius: rem(15);
                    border-bottom-right-radius: rem(15);
                }

                .searchable {
                    box-shadow: inset 0 rem(6) rem(12) rem(-6) rgba(24, 39, 75, .06);
                    background-color: $color-subtle-white;
                    border-bottom-left-radius: inherit;
                    border-bottom-right-radius: inherit;

                    .searchable_input-area {
                        padding-left: rem(26);
                        padding-right: rem(26);
                        .searchable_input {
                            width: 100%;
                            margin-top: rem(21);
                            margin-bottom: rem(21);
                            background-color: $color-light-gray-input;
                            box-shadow: 0 0 0 rem(1) $color-light-gray-input;
                            * {
                                font-family: $primary-font;
                                font-size: rem(14);
                            }
                            .search-icon {
                                path {
                                    fill: $color-primary;
                                }
                            }
                        }
                    }
                }

                .show-more-searchable {
                    &.searchable {
                        .searchable_content-area {
                            min-width: rem(508);
                            .show-more-less {
                                &.show-more-less--no-control {
                                    .show-more-less_wrapper {
                                        padding-bottom: rem(20);
                                    }
                                }
                            }
                            .show-more-less_wrapper {
                                padding-top: 0;
                                padding-bottom: 0;
                            }
                            .checkbox {
                                .checkbox_label {
                                    width: 100%;
                                    .checkbox_label-text {
                                        width: 100%;
                                        justify-content: space-between;
                                    }
                                }
                            }
                        }
                    }

                    .show-more-less {
                        min-width: rem(508);
                        // @media (min-width: 1010px) {
                        //     min-width: rem(508);
                        // }
                    }

                    .show-more-less_wrapper {
                        .checkbox {
                            &:not(:last-child) {
                                margin-bottom: rem(4);
                            }
                            .checkbox_label {
                                border-radius: rem(18);
                                &:hover {
                                    background-color: $color-light-gray-shade;
                                }
                            }
                            &.checkbox--checked {
                                .checkbox_label {
                                    background-color: $color-champagne-pink;
                                }
                            }
                        }
                    }

                    .show-more-searchable_empty {
                        padding: rem(20) rem(20) rem(40);
                        max-width: rem(178);
                        text-align: center;
                        margin: auto;
                        font-size: rem(14);
                        line-height: rem(17);
                    }
                }

                .action-bar {
                    border-top: rem(1) solid $color-light-gray-input;
                    padding: rem(15) rem(25) rem(25);
                }
                .more-inner {
                    .category {
                        .category_title {
                            margin-bottom: rem(20);
                        }
                    }
                }
            }
        }
    }
}

.search-results {
    animation: appear .5s ease-in-out forwards;
    opacity: 0;
    /*&.search-results_desktop,
    &.search-results_tablet {
        &.search-results_desktop_map_expanded,
        &.search-results_tablet_map {
            // .amenity-bar {
            //     &:not(.amenity-bar--active) + .map-container {
            //         .map-control.map-pin {
            //             margin-top: 0;
            //             top: rem(15);
            //             left: rem(120);
            //         }
            //     }
            //     &.amenity-bar--active {
            //         & + .map-container {
            //             .search_container {
            //                 top: rem(50);
            //             }
            //         }
            //     }
            // }

            .map-container {
                .search_container {
                    margin-left: rem(120);
                    max-width: rem(535);
                    @media (max-width: 760px) {
                        max-width: 60vw;
                        width: 60vw;
                    }

                    .search_empty .search_empty-desc {
                        font-size: rem(15);
                    }
                }
            }
        }
        .map-container {
            .search_container {
                .search_input {
                    border-radius: rem(11);
                }
            }
        }
    }*/

    &.search-results_mobile_map {
        .react-swipeable-view-container {
            border-radius: rem(24) rem(24) 0 0;
        }
    }
}
reach-portal {
    position: relative;
    .bottom-sheet {
        background: white;
        height: 90vh;
        overflow: auto;
        border-radius: 24px 24px 0px 0px;
        --rsbs-overlay-h: 90vh;
        margin-top: 30px;
        [data-rsbs-header] {
            margin-bottom: 7px;
            box-shadow: none;
        }
    }
}

.filters-tab-bar_mobile-head {
    display: flex;
    justify-content: space-between;
    .filters-tab-bar_mobile-close {
        flex: 0;
    }
    .filters-tab-bar_mobile-exit {
        background: none;
        border: none;
        outline: none;
        cursor: pointer;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: rgba(121, 120, 130, 0.1);
        display: grid;
        place-content: center;

        .filters-tab-bar_exit-icon {
            width: rem(15);
            height: rem(15);
            path {
                stroke-linecap: round;
                stroke: $color-black;
            }
        }
    }

    .filters-tab-bar_mobile-title {
        flex: 1;
        color: $color-black;
        font-size: 16px;
        line-height: 150%;
        font-weight: 600;
        text-align: center;
    }
    .filters-tab-bar_mobile-clear {
        flex: 0;
        font-size: 16px;
        line-height: 150%;
        font-weight: 600;
        white-space: pre;
        color: $color-grey-rare;
        letter-spacing: 0.16px;
        text-decoration: underline;
    }
    .filters-tab-bar_mobile-clear-notapplied {
        color: $color-subtle-grey;
    }
}


.filters-map_modal {
    .filters-map_modal-content {
        &.ReactModal__Content--after-open {
            transform: translateY(0);
            overflow: auto;
        }
        &.ReactModal__Content--before-close {
            transform: translateY(300%);
        }
        outline: transparent;
        height: 100%;
        background-color: $color-light-gray-shade;
        transform: translateY(300%);
        transition: transform 0.4s cubic-bezier(0.2, 0.8, 0.4, 1);
    }
    .filters-tab-bar {
        padding-left: rem(20);
        padding-right: rem(20);
        background-color: inherit;
       .filters-tab-bar_extended{
           margin-bottom: 80px;
       }
       .filters-tab-bar_prop-count-container {
        width: 100%;
        background: $color-white;
        position: fixed;
        bottom: 0px;
        left: 0px;
        right: 0px;
        }

        .filters-tab-bar_maximized {
            background-color: inherit;
            opacity: 1;
            transition: opacity .3s ease;;
            @include tabs;
            @include filtersTabBarPrimary;

            .filters-tab-bar_primary {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(2, auto);
                // gap: rem(10);
                border-radius: rem(15);

                .search-field_wrapper {
                    grid-column: 1 / span 2;
                    margin-left: initial;
                    margin-right: initial;


                    // border-bottom: rem(1) solid $color-shade-gray;
                    .search-icon {
                        left: rem(4);
                        // &.search-icon__highlighted {
                        //     path {
                        //         // fill: $color-white;
                        //     }
                        // }
                    }
                    .search-field.cn-autoComplete {
                        .cn-input-group {
                            .primary-input.autoCompleteSearch {
                                border-top-left-radius: rem(15);
                                border-top-right-radius: rem(15);
                            }
                        }
                    }
                }

                .filters-date-picker {
                    .DateInput {
                        width: rem(75);
                        &:first-child {
                            margin-right: rem(10);
                        }
                        &:last-child {
                            margin-left: rem(10);
                        }
                    }
                    border-radius: initial;
                    border-bottom-left-radius: rem(15);
                    box-shadow: none;
                    background-color: $color-field-grey;
                    // border-right: rem(1) solid $color-shade-gray;
                    &.date-picker__selected {
                        box-shadow: none;
                    }
                    &.date-picker__active {
                        background-color: $color-white;
                    }
                }

                .guest-selector {
                    box-shadow: none;
                    border-radius: initial;
                    border-bottom-right-radius: rem(15);
                    &.guest-selector__active {
                        background-color: $color-white;
                    }
                }
            }
        }
        .filters-tab-bar_prop-count-wrapper {
            display: flex;
            padding: 20px;
            .filters-tab-bar_prop-count {
                width: 100%;
                font-family: $primary-font;
                background: none;
                border: none;
                outline: none;
                background: linear-gradient(92.14deg, #F35597 1.79%, #F53959 99.81%);
                border-radius: 30px;
                font-size: 16px;
                line-height: 150%;
                font-weight: 400;
                color: $color-white;
                padding: 10px 0;
            }
        }
        @include extendedFiltersAccordeon;
        .filters-tab-bar_extended {

            .show-more-less {
                max-height: calc(100vh - 130px);
                .show-more-less_wrapper {
                    display: block;
                }
                .simplebar-scrollbar::before {
                    width: rem(4);
                    background: $color-subtle-grey;
                }

                .show-more-less_action-container {
                    padding-left: rem(20);
                    padding-right: initial;
                }

                .neighbourhood-inner_loader {
                    margin-bottom: rem(20);
                    display: grid;
                    grid-template-columns: max-content 1fr;
                    column-gap: rem(15);
                        .neighbourhood-inner_loader-particle {
                            &:last-child {
                                width: 100%;
                        }
                    }
                }
            }
            .drop-down {
                .drop-down_toggle {
                    padding-top: 13px;
                    padding-bottom: 13px;
                    max-width: unset;
                }
                .drop-down_inner {
                    .inner-wrapper-layout {
                        .inner-wrapper-layout_content-area {
                            .budget-inner {
                                .price-widget {
                                    &.price-widget__set-own-active {
                                        .price-widget_slider-container {
                                            margin-left: 50px;
                                            margin-right: 50px;
                                        }
                                    }
                                    // .price-widget_slider-wrapper {
                                    //     padding-left: rem(50);
                                    //     padding-right: rem(50);
                                    // }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.DateRangePicker_picker.DateRangePicker_picker__fullScreenPortal {
    animation: slideIn .5s ease-in-out;
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateY(200%);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.guest-selector_modal {
    .guest-selector_modal-overlay {
        display: flex;
        align-items: flex-end;

        .guest-selector_modal-content {
            &.ReactModal__Content--after-open {
                transform: translateY(0);
            }
            &.ReactModal__Content--before-close {
                transform: translateY(300%);
            }
            border-top-right-radius: 20px;
            border-top-left-radius: 20px;
            overflow: hidden;
            // overflow: initial;
            height: initial;
            width: 100%;
            max-height: 100%;
            transform: translateY(300%);
            transition: transform .3s ease-in-out;
            .guest-selector_mobile-scroll-wrapper {
                max-height: calc(100vh - 160px);
            }
        }
    }
}

/*.DateRangePicker_picker__portal {
    .DayPicker {
        transform: translateY(300%);
        animation: bottomSlide .5s ease-in-out .1s forwards;
    }
}

@keyframes bottomSlide {
    from {
        transform: translateY(300%);
    }
    to {
        transform: translateY(20%);
    }
} */

.mobile-filter_modal {
    .mobile-filter_modal-overlay {
        .mobile-filter_modal-content {
            .inner-wrapper-layout {
                height: 100%;
                width: 100%;
                @include innerWrapperLayoutContentMixin;
                .inner-wrapper-layout_content-area {
                    height: calc(100% - 142px);
                    border-radius: initial;
                    overflow-y: auto;
                    padding-left: rem(20);
                    padding-right: rem(20);
                    .services-inner {
                        .category_title, .checkbox:not(.services_checkbox__ungroupped) {
                            padding-left: rem(10);
                        }
                    }
                }
                .action-bar {
                    border-radius: initial;
                    position: relative;
                    padding-bottom: rem(20);
                    padding-top: rem(20);
                    box-shadow: 0 rem(4) rem(15) rgba(0, 0, 0, .15);
                }
            }
        }
    }
}

.ReactModal__Overlay {
    opacity: 0;
    transition: opacity .3s ease-in-out;
}

.ReactModal__Overlay--after-open{
    opacity: 1;
}

.ReactModal__Overlay--before-close{
    opacity: 0;
}

.text-helper{
    position: relative;
    display: table;
    width: calc(100% - 2.5rem);
    top: 0.25rem;
    color: $color-primary;
    font-size: 0.8rem;
    font-weight: 400 !important;
    margin-left: 1.25rem;
    -webkit-text-stroke: 0px !important;
}

.extra-bar_right {
    &:not(.extra-bar_right--mobile) {
        display: grid;
        grid-template-columns: repeat(2, max-content);
        column-gap: rem(10);
        align-items: center;
        align-self: center;
        .extra-bar_right_title {
            font-size: rem(14);
            font-weight: 600;
        }
        .drop-down-popover {
            padding: rem(8) rem(16);
            background-color: $color-champagne-pink;
            border-radius: rem(18);
            border: 1px solid transparent;
            font-weight: 400;
            transition: .3s ease-in-out;

            &.drop-down-popover__active {
                background-color: transparent;
                border-color: $color-field-grey;
            }
            .drop-down-popover_track {
                .outer_info-content {
                    display: grid;
                    grid-template-columns: repeat(2, max-content);
                    align-items: center;
                    column-gap: rem(10);
                    .drop-down-popover_toggle {
                        svg {
                            width: rem(10);
                        }
                    }
                }
            }
        }
    }
    .drop-down-popover {
        outline: 0;
        cursor: pointer;
        border: none;
        background-color: transparent;
        font-family: $primary-font;
        font-size: rem(14);
        font-weight: 500;
        line-height: rem(18);
        letter-spacing: 0.01em;
        color: $color-black;

        .sort-outer {
            display: flex;
            align-items: center;
            justify-content: center;

            // svg {
            //     margin-right: rem(5);
            // }
        }
    }
}
