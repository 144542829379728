.page-listings {
    .MobileHeader_band {
        margin-top: 20px;
        position: fixed;
        height: 50px;

        &.MobileHeader_band__open {
            margin-top: 0;
            z-index: 104;
            background-color: $color-black;
            height: 75px;

            .MobileHeader_logo {
                visibility: unset;
            }

            .MobileHeader_menu_symb {
                svg {
                    margin-top: unset;
                }
            }
        }

        .MobileHeader_logo {
            visibility: hidden;
        }

        .MobileHeader_menu_symb {
            svg {
                margin-top: -50px;
            }
            path {
                stroke: rgb(0, 0, 0);
            }
        }
    }

    height: auto !important;
    grid-column: 1/18;
    display: flex;
    padding: 0;
    flex-direction: column;
    //background-color: var(--color-subtle-white);
    background-color: white;
    --gp-section-compact-padding: 32px !important;
    --gp-section-compact-padding-condensed: 16px !important;
    --gp-section-standard-padding: 48px !important;
    --gp-section-standard-padding-condensed: 24px !important;
    //Cookie banner
    .cn__cookie-wrapper{
        .cn__cookie{
            box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12);
            outline: 1px solid #DFE2E5;
        }
    }
    header.header {
        z-index: 4;
        &.header-mob {
            z-index: 1;
            &.header_menu--open, &.header_menu--active {
                z-index: 6;
            }
            top: 100px;
            right: 0;
            display: flex;
            width: max-content;
            .logo-mob {
                display: none;
            }
            .menu-mob {
                .hamburguer-menu:not(.hm-opened) {
                    .hamburguer-menu-lin1, .hamburguer-menu-lin2, .hamburguer-menu-lin3{
                        background-color: var(--color-white);
                    }
                .hamburguer-menu-lin3{
                    transition: all 0.3s ease 0s;
                    transform: translate(0px, -50%);
                    transform-origin: center center;
                    position: absolute;
                    top: 50%;
                    right: 4px;
                    speak: none;
                    width: 24px;
                    height: 2px;
                    border-radius: 3px;
                    z-index: 2;
                    margin-top: 12px;
                }
                }
            }
        }
        .header-grid {
            grid-template-columns: 0px repeat(7, 1fr 20px) 1fr 0px;
            .header-nav_blog-menu-item {
                display: none;
            }
        }
        @include tablet {
            padding-left: 20px;
            padding-right: 20px;
        }
        @include desktop {
            padding-left: 50px;
            padding-right: 50px;
        }
        @include large_desktop {
            padding-left: calc( (100vw - 1074px) / 2);
            padding-right: calc( (100vw - 1074px) / 2);
        }
        @media (min-width: 1681px) {
            padding-right: calc( (100vw - 1340px) / 2);
            padding-left: calc( (100vw - 1340px) / 2);
        }
    }
    main {
        // padding-top: 90px;
        background: white;
        & > span {
            display: inline-block;
            width: 100%;
            margin-top: 20px;
        }
        section:first-of-type {
            padding-top: 35px;
        }
        @include tablet {
            padding-top: 0;
            // padding-top: 35px;
        }
        hr {
            border-top: 1px solid #E9E9EB;
        }
        .page-listings__footer {
            .cn__hp__seo {
                margin-top: unset;
                padding-top: 10px;
            }
        }
        @media (min-width: 618px) {
            padding-left: 0;
            padding-right: 0;

            & > span,
            .listings-header,
            .listings-navbar,
            .listings-hotel,
            .listings-room,
            .page-listings__footer {
                padding-left: 20px;
                padding-right: 20px;
            }
        }

        @media (min-width: 1136px) {
            & > span,
            .listings-header,
            .listings-navbar,
            .listings-hotel,
            .listings-room,
            .page-listings__footer {
                padding-left: 50px;
                padding-right: 50px;
            }
        }

        @media (min-width: 1440px) {
            & > span,
            .listings-header,
            .listings-navbar,
            .listings-hotel,
            .listings-room,
            .page-listings__footer {
                padding-left: calc( (100vw - 1074px) / 2);
                padding-right: calc( (100vw - 1074px) / 2);
            }
        }
        @media (min-width: 1681px) {
            & > span,
            .listings-header,
            .listings-navbar,
            .listings-hotel,
            .listings-room,
            .page-listings__footer {
                    padding-right: calc( (100vw - 1340px) / 2);
                    padding-left: calc( (100vw - 1340px) / 2);
            }
        }
        .listings__faq {
            padding-left: 0;
            padding-right: 0;
        }
    }
    &__footer {
        overflow-x: hidden;
        margin-top: 10px;
        .list--places-nearby {
            ul {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                @include desktop {
                    grid-template-columns: repeat(4, 1fr);
                    grid-gap: 5px;
                }
                li {
                    a {
                        color: var(--color-subtle-grey);
                        font-size: 14px;
                    }
                }
            }
        }
        .cn__hp__seo {
            margin-top: 52px;
            margin-bottom: 0;
            padding-left: 0;
            padding-right: 0;
            @media (max-width: 600px) {
                margin-top: 88px;
            }
            .cn__hp__seo__options {
                @media (max-width: 600px) {
                    display: block;
                }
            }
            .cn__hp__seo__listing{
                @media (max-width: 600px) {
                    margin-top: 7px;
                }
            }
            .cn__hp__seo__listing .simplebar-track.simplebar-horizontal {
                background-color: transparent;
                &::before {
                    background-color: transparent;
                }
            }
        }
        .breadcrumbs {
            margin-top: 50px;
            margin-bottom: 100px;
        }
    }
    .listing-details--map-container {
        .listing-details-map {
            min-height: 450px;
            .gmnoprint a, .gmnoprint span, .gm-style-cc {
                display: none;
            }
            .gmnoprint div {
                background: none !important;
            }
        }
        .search_container {
            margin-top: 10px;
            margin-left: 10px;
        }
        .map-control.map-pin {
            .map-pin-icon {
                margin-left: initial;
                margin-right: initial;
            }
        }
        @media (max-width: 600px) {
            .map-control {
                border-radius: 10px;
                width: 40px;
                height: 40px;
            }
            &.map-search__search {
                .search_container {
                    margin: initial;
                    padding: initial;
                    top: initial;
                    bottom: 0;
                    border-bottom-left-radius: initial;
                    border-bottom-right-radius: initial;
                    max-width: initial;
                    width: 100%;
                }
            }
            &.map-container__fullscreenActive {
                border-radius: initial;
                .map-control {
                    &.map-viewtype, &.map-pin {
                        display: block;
                        margin-top: 0;
                    }
                    &.map-zoom-in, &.map-zoom-out {
                        display: flex;
                    }
                }
            }
            &.map-search__found {
                .map-control.map-pin {
                    display: flex;
                }
            }
        }
        &.map-search__found {
            .map-control.map-pin {
                width: initial;
                min-width: initial;
                .map-pin-icon {
                    margin-left: 5px;
                    margin-right: 5px;
                }
            }
        }
    }

    .listings-room--desktop,
    .listings-hotel--desktop {
        padding-top: 30px;
        .listings__grid-area {
            display: grid;
            //grid-template-columns: 1fr 400px;
            grid-template-columns: 1fr 433px;
            column-gap: 29px;
            .listings-description-card-title {
                .card__title {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                }
            }
        }
    }

    .listings-room--tablet,
    .listings-hotel--tablet {
        .listings__grid-area {
            .overview {
                margin-top: 30px;
            }
            .safety-message {
                margin-top: 30px;
                margin-bottom: 30px;
            }
        }
    }

    .listing-safety-message--tablet {
        max-width: 80%;
    }
    .pointer_none {
        pointer-events: none;
    }
    .listings-hotel--desktop {
        .listings-room__form {
            animation: none;
            transform: none;
            position: -webkit-sticky;
            position: sticky;
            top: calc(88px + 68px);
            // top: 75px;
            height: min-content;
            box-shadow: none;
            z-index: 2;
            width: 100%;
            .card--booking-form {
                opacity: 0;
                animation: appear 0.3s ease-in-out forwards;
                padding: 0;
                border-radius: 15px;
                box-shadow: 0px 2px 20px rgb(36 37 38 / 15%);
            }

            .safety-message {
                margin-top: 20px;
            }
        }
    }

    .listings-section-separator {
        opacity: 0;
        &.listings-section-separator--no-margin {
            margin-top: 0;
        }
    }
    .property_report{
        .property_report_container {
            display: flex;
            gap: 15px;
            cursor: pointer;
            .property_report_title{
                color: var(--color-black);
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 120%;
                text-decoration-line: underline;
            }
        }
    }
    .expidia_property_policies {
        .expidia_property_policies_container{
            // margin-left: 30px;
            margin-top: -20px;
            @media (min-width: 600px) {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            gap: 20%;
            }
            @media (max-width: 600px) {
                margin-left: unset;
            }
            .expidia_property_policies_check_in_out{
                display: flex;
                @media (max-width: 600px) {
                    display: unset;
                }
            }
            .remove_flex {
                display: unset;
            }
            .expidia_property_policies_text_description {
                // margin-left: 10px;
                p >b {
                    font-weight: 600;
                }
            }
            ul{
                margin-left: 16px;
                // margin-left: 30px;
                margin-top: 15px;
                margin-bottom: 15px;
                li{
                    font-size: 16px;
                    font-weight: 400;
                    margin-top: 7px;
                }
            }
        }
        .expidia_property_policies_text {
            font-weight: 600;
            font-size: 23px;
            line-height: 120%;
            color: var(--color-black);
            margin-bottom: 15px;
            margin-top: 15px;
            font-feature-settings: unset;
            font-variant: unset;
            text-transform: unset;
        }
        .expidia_property_policies_text_subtitle {
            font-weight: 600;
            font-size: 18px;
            line-height: 120%;
            color: var(--color-black);
            margin-bottom: 15px;
            margin-top: 45px;
        }
        .expidia_property_policies_text_subtitlesmall {
            font-weight: 600;
            font-size: 16px;
            line-height: 120%;
            color: var(--color-black);
            margin-bottom: 10px;
            margin-top: 25px;
        }
        .expidia_property_policies_text_detail {
            margin-left: 20px;
        }
        .expidia_property_policies_payment {
            display: flex;
            gap: 15px;
            margin-top: 25px;
            flex-wrap: wrap;
            .expidia_property_policies_payment_item {
                border-radius: 7px;
                height: 90px;
                width: 90px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #F7F7F7;

                img {
                    height: inherit;
                    width: inherit;
                }
            }
            .expidia_property_policies_payment_viewmore {
                display: flex;
                flex-direction: column;
                gap: 5px;
                cursor: pointer;
                .expidia_property_policies_payment_viewmore_box {
                    color: #797882;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 150%; /* 21px */
                    letter-spacing: 0.14px;
                    height: 44px;
                    width: 48px;
                    border: 0.5px solid #DFE2E5;
                    text-align: center;
                    align-content: center;
                }
                span {
                    color: #797882;
                    font-size: 8px;
                    font-weight: 400;
                    line-height: 150%;
                    letter-spacing: 0.08px;
                }
            }
            @media (max-width: 600px) {
                display: grid;
                grid-template-columns: repeat(5, minmax(max-content, 0fr));
                grid-gap: 10px;
                img{
                    height: 60px;
                    width: 53px;
                }
                img:nth-child(1) {
                    object-fit: cover;
                }
            }
        }
    }

    .cancellation-policy-span {
        @media (min-width: 618px) {
            font-size: 14px;
        }

        @media (min-width: 700px) {
            font-size: 1rem;
        }
        a {
            color: var(--color-black);
        }
        .ic-icon {
            position: relative;
            top: 0px;
        }
        .n-tooltip--left .n-tooltip__inner {
            right: 30px;
        }
    }

    &.page-listings-hotel {
        .cn__shortfooter__wrapper {
            @media (min-width: 618px) {
                padding-bottom: 120px;
            }
            @media (min-width: 1154px) {
                padding-bottom: 30px;
            }
        }
    }
    &.page-listings-vr {
        .cn__shortfooter__wrapper {
            padding-bottom: 115px;
            @media (min-width: 618px) {
                padding-bottom: 150px;
            }
            @media (min-width: 1154px) {
                padding-bottom: 30px;
            }
        }
    }
    .cn__shortfooter__wrapper {
        @media (min-width: 1136px) {
            padding-right: 50px;
            padding-left: 50px;
        }
        @media (min-width: 1440px) {
            padding-right: calc( (100vw - 1074px) / 2);
            padding-left: calc( (100vw - 1074px) / 2);
        }
        @media (min-width: 1550px) {
            padding-right: calc( (100vw - 1340px) / 2);
            padding-left: calc( (100vw - 1340px) / 2);
        }
        .cn__shortfooter {
            padding-top: 0;
            // @media (min-width: 600px) {
            //     padding-top: 35px;
            // }

            @media (min-width: 1136px) {
                padding-left: 0;
                padding-right: 0;
            }
            /*@media (min-width: 1136px) {
                padding-right: 50px;
                padding-left: 50px;
            }
            @media (min-width: 1440px) {
                padding-right: calc( (100vw - 1340px) / 2);
                padding-left: calc( (100vw - 1340px) / 2);
            }*/
        }
    }
    .footer {
        .footer-social-links {
            @media (max-width: 600px) {
                margin-bottom: 30px;
            }
            @media (max-width: 1136px) {
                margin-bottom: 30px;
            }
        }
        .footer-copy-right{
            @media (max-width: 600px){
                margin-bottom: 100px;
            }
            @media (max-width: 1136px) {
                margin-bottom: 120px;
            }
        }
    }

    .product-section {
        &:not(.product-section--anchored) {
            margin-top: 28px;
        }
        .card__title{
            font-weight: 600;
            font-size: 23px;
            line-height: 1.2;
            letter-spacing: 0;
        }
    }
    .product-section_title {
        font-size: 23px;
        font-weight: 600;
        color: var(--color-black);
        &.product-section_title--spaced {
            margin-bottom: 20px;
        }
    }
    .product-amenities-wrapper{
        width: max-content;
        @media (max-width: 600px) {
            width: 100%;
        }
        .amenity-note {
            display: flex;
            gap: 10px;
            margin-top: 10px;
            @media (max-width: 600px) {
                margin-top: 20px;
                align-items: center;
            }
            .amenity-note-icon {
                svg path {
                    fill: var(--color-cn-pink);
                }
            }
            .amenity-note-detail {
                color: var(--color-pure-black);
                font-size: 12px;
                font-weight: 400;
                line-height: 150%;
                letter-spacing: 0.12px;
                @media (max-width: 600px) {
                    line-height: 120%;
                }
            }
        }
    }


    .cn__ldp__hostsection{
        position: relative;
        padding: 51px 0;
        border-top: 1px solid #E9E9EB;
        border-bottom: 1px solid #E9E9EB;
        margin-top: 52px;
        display: flex;
        align-items: center;

        &__image{
            position: relative;
            width: 92px;
            height: 92px;
            border-radius: 50%;
            overflow: hidden;
            flex-shrink: 0;

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &__info{
            position: relative;
            margin-left: 21px;

            &__title{
                font-weight: 600;
                font-size: 23px;
                line-height: 1.2;
                color: #242526;
            }

            &__hostinfo{
                position: relative;
                display: flex;
                align-items: center;
                gap: 10px;
                margin-top: 10px;

                @media (max-width: 600px) {
                    flex-direction: column;
                    align-items: flex-start;
                }

                &__option{
                    position: relative;
                    display: flex;
                    align-items: center;
                    gap: 5px;

                    svg{
                        flex-shrink: 0;
                    }

                    p{
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 1.5;
                        letter-spacing: 0.01em;
                        color: #797882;
                    }

                    @media (max-width: 600px) {
                        &:first-child{
                            order: 2;
                        }
                        &:last-child{
                            order: 1;
                        }
                    }
                }
            }

            &__contact{
                position: relative;
                padding: 10px 6px 10px 10px;
                background: #FFFFFF;
                box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
                border-radius: 10px;
                margin-top: 10px;
                cursor: pointer;
                display: table;

                a{
                    display: inline-flex;
                    align-items: center;
                    gap: 10px;
                    color: #000000;
                }
            }
        }
    }

    .reserve-btn {
        margin: 20px 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}