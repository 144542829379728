.listings-navbar {
    &.nav {
        grid-area: 1 / 1 / 1 / 4;
        height: 68px;
        width: 100%;
        max-width: 100vw;

        ul {
            overflow: auto hidden;
            scroll-snap-type: x mandatory;

            &::-webkit-scrollbar {
                display: none;
            }

            -ms-overflow-style: none;
            scrollbar-width: none;
        }
        .currency-item {
            margin-left: auto;
        }
        li {
            &.active::after {
                bottom: 0;
                border-radius: 5px;
            }
            &:first-child {
                padding-left: 20px;
            }
            &:last-child {
                padding-right: 20px;
            }
            scroll-snap-align: start;
            cursor: pointer;
            white-space: nowrap;
            @include text_body-text;
        }
    }

    &.listings-navbar--mobile {
        //background-color: var(--color-subtle-white);
        background: white;
        position: fixed;
        top: 30px;
        left: 0;
        z-index: 4;
        height: auto;
        padding-top: 7px;
        ul {
          .currency-item {
                display: none;
            }
         li {
            &:first-child {
                scroll-snap-align: start;
            }

            // &:last-child {
            //     scroll-snap-align: end;
            //     display: none;
            // }

            scroll-snap-align: center;
        }
     }
    }
}