.similar-accommodation {
    opacity: 0;
    animation: appear .3s ease-in-out .3s forwards;
    @media (max-width:700px) {
        // padding-bottom: 80px;
        padding-bottom: 150px;
    }
    .similar-accommodation_slider {
        height: 300px;
        .simplebar-content-wrapper {
            scroll-snap-type: x mandatory;
        }

        .desktop-marker-info-window {
            .property-card_grs-rating_container {
                display: block;
            }
        }
    }

    .cn__slider {
        position: absolute;
        width: 100%;
        left: 0px;
    }

    .cn__slider__cards {
        overflow: initial;
    }

    .similar-accommodation_property {
        max-width: 320px;
        scroll-snap-align: center;
        background-color: var(--color-white);
        box-shadow: 0 5px 30px rgba(231, 228, 228, 0.5);
        cursor: pointer;

        &.property-card_with_amenities_count {
            .property-card_name__type__name {
                max-width: 40%;
                .property-card_name__type__name__title{
                    text-transform: capitalize;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }

            display: flex;
            justify-content: space-between;
            .property-card_amenities_with_count {
              &, .property-card_amenities_with_count_list {
                display: flex;
                justify-content: center;
                align-items: center;
                svg {
                    width:16px;
                    height:16px;
                }
                span {
                    color: #797882;
                    font-size: 14px;
                    line-height: 2px;
                }
              }

              .property-card_amenities_with_count_list {
                width:43px;
                &:not(:last-child) {
                    border-right: 1px solid #E9E9EB;
                }
                .property-card_amenities_icon {
                    height:21px;
                    margin-right: 5px;
                    display: flex;
                    align-items: center;
                }
              }
            }
        }

        .desktop-marker-info-window_desc {
            background-color: var(--color-white);
            min-height: 200px;//228px;
            .property-card_distance_withoutneighbour {
                max-width: 220px;
            }
            .desktop-marker-info-window_property-wrapper {
                .property-card-price {
                    margin-top: 6px;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;

                    .property-card_price-actual,
                    .property-card_price-info {
                        color: var(--color-dark-grey);
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 150%;
                    }

                    .property-card_price-total {
                        color: var(--color-another-black);
                        font-size: 20px;
                        font-weight: 600;
                        line-height: 120%;

                        @media screen and (max-width: 600px) {
                            .property-card_price-actual__active {
                                font-size: 12px;
                                font-weight: 400;
                                line-height: 150%;
                            }
                        }
                    }
                }
            }
        }

        .desktop-marker-info-window_image-slider {
            .slick-slide {
                max-height: 165px;
                img {
                    height: 165px;
                }
            }
        }
    }

    .cn__slider__arrows {
        display: none;
    }

    @media (min-width: 600px) {
        .similar-accommodation_property {
            scroll-snap-align: unset;
        }

        .similar-accommodation_slider {
            height: auto;
        }

        .cn__slider {
            position: relative;
        }

        .cn__slider__cards {
            padding-left: 10px;
        }


    }

    @media (min-width: 1120px) {
        .similar-accommodation_slider {
            margin-top: 16px;
        }

        .cn__slider__arrows {
            display: flex;
        }
    }

    @media (min-width: 1137px) {
        .similar-accommodation_property {
            max-width: initial;
            box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12), 0px 10px 32px -4px rgba(24, 39, 75, 0.1);
        }

        .cn__slider__cards {
            padding-left: initial;
        }
    }
}
