.nav {
    border-bottom: 1px solid #E9E9EB;
    ul {
        list-style: none;
        display: flex;
        li {
            padding: 23px 9px;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            color: var(--color-black);
            position: relative;
            &:after {
                position: absolute;
                content: '';
                height: 3px;
                width: 0px;
                bottom: -2px;
                left: 50%;
                border-radius: 1px;
                background: transparent;
                transition: width .5s ease, left .5s ease, background-color .5s ease;
            }
            &.active {
                font-weight: 600;
                &:after {
                    position: absolute;
                    content: '';
                    height: 3px;
                    bottom: -2px;
                    border-radius: 1px;
                    left: calc(50% - 20px);
                    width: 40px;
                    background: #F35597;
                }
            }
        }
    }
    &--sticky {
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        // top: 88px;
        //background-color: var(--color-subtle-white);
        background-color: white;
        z-index: 3;
    }
}