 .cn__homepage{
    .MobileHeader_band {
        transform: none;
    }
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    .cn__hp__gradient{
        background: linear-gradient(180deg, rgba(255, 253, 245, 0) 0%, rgba(255, 253, 245, 0.4) 100%);
        @media screen and (max-width:600px){
            margin-bottom: 60px;
        }
    }
    .cn__hp__download_wrapper {
        position: fixed;
        z-index: 2;
        bottom: 0;
        width: 100%;
        padding: 1rem;
        display: flex;
        justify-content: flex-end;
        pointer-events: none;

        @media screen and (max-width:950px){
           position: absolute;
           bottom: 100px;
        }
        @media screen and (max-width: 600px) {
            justify-content: center;
            position: fixed;
            bottom: 0;
        }
    }
    .cn__hp__download__floating{
        font-weight: 700;
        font-size: 20px;
        line-height: 100%;
        color: $color-white;
        // position: fixed;
        // bottom:1rem;
        // right: 1rem;
        padding: 0.5rem;
        // z-index: 2;
        // width: 176px;
        // height: 110px;
        width: 140px;
        box-shadow: 0 4px 32px rgb(0 0 0 / 10%);
        background-color: $color-deep-black;
        border-radius: 20px;
        padding: 8px;
        display: flex;
        transition: 100ms ease;
        overflow: hidden;
        pointer-events: auto;

        &.cn__hp__download__floating_moveTop {
            position: absolute;
            top:auto;
            bottom: 150px;
            overflow: hidden;
            &.with_cookie {
                transition: 300ms ease;
                position: fixed;
                bottom: 190px;
                &.isScrolled {
                    bottom: 140px;
                }
            }
        }
    .cn__hp__download__info__buttons__qr{
        width: 72px;
        height: 72px;
        transition: 100ms ease;
    }
    .cn__hp__download__floating__text{
        // margin-top: auto;
        // margin-bottom: auto;
        margin-left: 0.5rem;
        display: flex;
        align-items: center;
        width: 2.75rem;
        word-spacing: 2.75rem;
        font-size: 18px;
        font-weight: 800;
    }
    .cn__hp__download__floating__mobile{
        display: flex;
        font-weight: 700;
        font-size: 18px;
        line-height: 120%;
    }
    &:hover{
        width: 140px;
        height: 140px;
        display: grid;
        cursor: none;
        .cn__hp__download__floating__text{
            display: none;
        }
        .cn__hp__download__info__buttons__qr{
            width: 124px;
            height: 124px;
        }
    }
    // .cn__hp__download__info__buttons__qr{
    //     margin-right: 15px;
    // }
 }
 .cn__hp__download__floating__mobile__container {
        width: 188px;
        height: 51px;
        font-weight: 700;
        font-size: 20px;
        line-height: 100%;
        color: $color-white;
        // position: fixed;
        // bottom: 0%;
        // left: 50%;
        // -webkit-transform: translate(-50%,-50%);
        // -moz-transform: translate(-50%,-50%);
        // transform: translate(-50%,-50%);
        padding: 0.5rem;
        z-index: 2;
        box-shadow: 0 4px 32px rgb(0 0 0 / 10%);
        background-color: $color-deep-black;
        border-radius: 20px;
        padding: 13px;
        display: flex;
        transition: 100ms ease;
        pointer-events: auto;

        svg {
            width: 23px;
            height: 16px;
            margin-right: 5px;
            path {
                fill: #FFFFFF;
            }
        }
        .cn__hp__download__floating__mobile {
            display: flex;
            align-self: center;
        }
 }
}

//Header login icon
.cn__header__logicon{
    padding: 5px 10px;
    border-radius: 15px;
    background: #FFFFFF;
    svg{
        margin-right: 0!important;
        path{
            fill: $color-black !important;
        }
    }
}

//For the Portal
.cn__portal{
    position: absolute;
    top: 0;
    width: 100%;
    .omnisearch_input-wrapper_mobile_input-container{
        //TABLET 601
        @media screen and (min-width:601px){
            top: 27vh;
        }
        //TABLET 764
        @media screen and (min-width:764px){

        }
        //TABLET 800
        @media screen and (min-width:800px){

        }
    }
}

.expedia_room_price {
    position: relative;
    z-index: 9999;
}