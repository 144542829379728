
.personalAccountProfile-container {
    padding:0px 40px;
    height: 100%;
    overflow-y: auto;
    
    @media screen and (max-width: 481px) { 
        padding:0px 20px;
    }
}

.accountProfile-verification-container {
    height:35px;
    display: flex;
    .unverifed_container {
        display: flex;
        width: 100%;
        justify-content: space-between;
            div {
                display: flex;
                gap: 4px;
                align-items: center;
            }
    }
    &.verification-container-hidden {
        visibility: hidden;
    }

    span.actionButton {
        font-size: 14px;
        font-weight: 400;
        color: $color-pure-black;
        text-decoration: underline;
        cursor: pointer;
    }
}
   
   .profile-form {  
    .sassy--select{border:none;border-radius:.25rem;display:inline;padding:.2rem .4rem}.sassy--select__container{border-radius:.25rem;position:relative}.sassy--select:focus{box-shadow:var(--ring-shadow);outline:none}.sassy--select.sassy--select__disabled{opacity:var(--disabled-opacity);pointer-events:none}.sassy--select.sassy--select__disabled:focus{box-shadow:none}.sassy--select__dropdown{background:var(--select-background);border:none;border:var(--outline);border-radius:.5rem;box-shadow:var(--shadow-md);display:flex;flex-direction:column;max-height:40vh;overflow:auto;padding:.5rem;position:absolute;scrollbar-width:none;top:1.5rem;z-index:2}.sassy--select__dropdown::-webkit-scrollbar{display:none}.sassy--option,.sassy--select{font-family:var(--font);font-size:1rem;font-weight:500}.sassy--option:focus{box-shadow:var(--ring-shadow);outline:none}.sassy--option__disabled{color:var(--disabled-color);pointer-events:none}.sassy--select:hover{cursor:context-menu}.sassy--option{background:var(--option-background);border:none;border-radius:.25rem;cursor:pointer;margin-top:.25rem;padding:.25rem .4rem;transition:all .1s ease}.sassy--option:first-of-type{margin-top:0}.sassy--option:hover,.sassy--option__active{background-color:$color-primary;box-shadow:var(--shadow);color:$color-white}
.sdp{background:var(--background);border:none;border:var(--outline);border-radius:.5rem;box-shadow:var(--shadow);padding:.75rem;width:16rem}.sdp--disabled{pointer-events:none}.sdp--month-name:focus,.sdp--square-btn:focus,.sdp:focus{box-shadow:var(--ring-shadow);outline:none}.sdp--month-name{border:none;border-radius:.5rem;padding:.5rem}.sdp--text{font-family:var(--font);font-weight:500;margin:0}.sdp--text__inactive{color:var(--disabled-color);pointer-events:none}.sdp--square-btn{align-items:center;background-color:var(--btn-background);border:none;border-radius:.5rem;cursor:pointer;display:flex;font-family:var(--font);height:1.8rem;justify-content:center;transition:all .2s ease-in-out;width:1.8rem}.sdp--square-btn__outlined,.sdp--square-btn__outlined:focus:hover{border:var(--outline)}.sdp--square-btn:focus:hover{box-shadow:none}.sdp--square-btn__shadowed,.sdp--square-btn__shadowed:focus:hover{box-shadow:var(--shadow)}.sdp--date-btn__selected{background-color:$color-primary;box-shadow:var(--shadow);color:$color-white}.sdp--date-btn__selected.sdp--square-btn:focus{box-shadow:var(--shadow)}.sdp--header{align-items:center;display:flex;justify-content:space-between}.sdp--grid{display:grid;gap:4px;grid-template-columns:repeat(7,1fr);justify-items:center;margin:.5rem 0 0}.sdp--grid>p.sdp--text{padding:.5rem 0}.sdp--header__main{align-items:center;display:flex;font-weight:700;justify-content:center}.sdp--select__month button{text-align:left}
.sdp,.stp{--font:inherit;--shadow:transparent 0px 0px 0px 0px,transparent 0px 0px 0px 0px,rgba(0,0,0,.1) 0px 1px 3px 0px,rgba(0,0,0,.06) 0px 1px 2px 0px;--shadow-md:transparent 0px 0px 0px 0px,transparent 0px 0px 0px 0px,rgba(0,0,0,.1) 0px 4px 6px -1px,rgba(0,0,0,.06) 0px 2px 4px -1px;--theme-color:#60a5fa;--text-color:#1f1f1f;--background:#fff;--btn-background:#fff;--select-background:#fff;--option-background:#fff;--selected-date-color:#fff;--disabled-color:#bdbdbd;--disabled-opacity:50%;--outline:1px solid #cbd5e1;--ring-shadow:0px 0px 0px 2px rgba(97,166,250,.7);background:var(--background);color:var(--text-color)}
.stp{border:none;border:var(--outline);border-radius:.5rem;box-shadow:var(--shadow);display:inline-flex;font-family:var(--font);outline:none;padding:.5rem .4rem}.stp--disabled{pointer-events:none}.stp--divider{align-items:center;display:inline-flex;justify-content:center}.stp--divider__disabled{opacity:var(--disabled-opacity)}.stp *{outline:none}.stp:focus{box-shadow:var(--ring-shadow)}.stp>p{display:inline;margin:0 2px}    
  
        .profile-form__datePickerDropper { 
            transition:300ms ease; 
            position:absolute; 
            opacity: 0;
            z-index: 999;
            display:none;
            top: 50px;
            &.profile-form__openDatePicker {
                top:60px;
                display:block;
                opacity: 1;
            }
        }
        .profile-form__actions {
            width: 100%;
            @media (min-width:600px) { 
                max-width:330px;
            }
            button {
                width:80%;
            }
        }    
        .profile-form__wrap {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
        }
        .profile-form__container__optional {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: $color-dark-grey !important;
        }
        &, &__container {
            .profile-form__container__field {
                position: relative;
                .profile-form__fields__character {
                    position: absolute;
                    bottom: 5px;
                    right: 5px;
                    font-size: 12px;
                    color: gray;
                }
            }
            max-width:480px;
            @media (max-width:1000px) { 
                max-width:100%;
            }
            width: 100%;  
            &.custom_gender_container {
                @media (max-width:600px) {
                    margin-bottom: 10px;
                    flex-direction: column !important;
                }
            }
            &.col-2 {
                width:50%;
                @media (max-width:600px) {
                    width:100%;
                    margin-left:0px;
                    margin-right: 0px;
                }
            }
            display: flex;
            flex-direction: column;
            &.flex-row {
                flex-direction: row;
                gap: 10%;
                align-items: flex-start;
                @media (min-width:600px) {
                    flex-direction: row;
                }
                
                align-items: flex-start;
            }
            &.items-left {
                align-items: flex-start;
                margin-left: 7px;
            }
            .profile-form__container__mask {
                position: relative;
                input {
                    background: $color-white;
                    width: 100%;
                    border: 1px solid $color-dark-grey;
                    box-shadow: 0px 5px 30px rgba(231, 228, 228, 0.5);
                    border-radius: 100px;
                    height: 46px;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    padding:0px 30px;
                    line-height: 150%;
                    color: $color-pure-black;
                }
            }
            .profile-form__container__withVerification {
                display: flex;
                flex-direction: column;
                align-items: top;
                justify-content: stretch;
                .profile-form__container__field { 
                    flex:1;
                    @media (max-width:600px) {
                        margin-right: 0px;
                    }
                }
                .profile-form__container__verificationStatus {
                    margin-top: 6px;
                    margin-bottom: 10px;
                }
            }
            label:not(.profile-form__fields) {
                font-style: normal;
                font-weight: 400;
                padding-left: 20px;
                display: inline-block;
                font-size: 16px;
                color: $color-dark-grey;
                margin-bottom: 5px;
                span {
                    color: $color-danger;
                }
                &.profile-form__container__custom_gender {
                    margin-bottom: 0px;
                    @media (max-width:600px) {
                        margin-right: 0px;
                        padding:0px;
                    }
                }
            } 
            
            &__radio-group {
                display: flex;
                justify-content: space-between;
                @media (max-width:600px) {
                    flex-direction: row;
                    align-self: center;
                }
            }
            .profile-form__fields {
                &:focus {
                    outline-color: $color-black;
                }
                &[type="text"][name="email"]:read-only  {
                    opacity: 0.3;
                }
                
                &__error {
                    color: $color-error-red;
                    text-align: right;
                    margin-right: 15px;
                    font-style: italic;
                    font-weight: 600;
                    min-height: 18px;
                    font-size: 12px;
                }
                .phone-input__container {
                    .phone-input__button .selected-flag .flag .arrow {
                        display: block;
                        left: 26px;
                        border: solid $color-subtle-grey;
                        border-width: 0 2px 2px 0;
                        padding: 2px;
                        transform: rotate(-135deg);
                    }
                    .phone-input__button .selected-flag .flag .up {
                        transform: rotate(45deg);
                        top: 28%;
                    }
                    .phone-input__input {
                        border: solid 1px $color-dark-grey;
                        padding-left: 79px;
                    }
                    width: 100%;
                    input.phone-input__input:focus {
                        outline: auto;
                    }
                    .phone-input__button {
                        background-color: $color-light-gray-input;
                        border: 1px solid $color-dark-grey;
                        width: 72px;
                        
                        .selected-flag:hover, .selected-flag:focus {
                            background-color: transparent;
                        }
                    }
                }
                &.radio {
                    background: none;
                    padding:0px;
                    input[type="radio"] {
                        display: none;
                    }
                                    
                    .radio_label { 
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        color: $color-dark-grey;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-transform: capitalize;
                        margin-right: 25px;
                        position: relative;
                        .radio-selector {
                            width: 16px;
                            height: 16px;
                            border-radius: 16px; 
                            margin-right: 15px;
                            background: #FFFFFF;
                            border: 2px solid #DFE2E5;
                            box-shadow: 0px 5px 30px rgba(231, 228, 228, 0.5);
                            line-height: 10px;
                            text-align: center;
                        }
                    }
                    input[type="radio"]:checked +  .radio_label {
                        color: $color-dark-grey;
                        .radio-selector {
                            border-color: $color-primary;
                            background: $color-primary;
                            
                        }
                    }
                }  
            }  
                            
            input.profile-form__fields, .textarea.profile-form__fields, select.profile-form__fields  {
                background: $color-white;
                width: 100%;
                border: 1px solid $color-dark-grey;
                box-shadow: 0px 5px 30px rgba(231, 228, 228, 0.5);
                border-radius: 100px;
                height: 46px;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                padding:0px 30px;
                line-height: 150%;
                color: $color-pure-black;
            } 
            .textarea.profile-form__fields {
                resize: vertical;
                height: 128px;
                min-height: 128px;
                background: $color-white;
                border: 1px solid $color-dark-grey;
                box-shadow: 0px 5px 30px rgba(231, 228, 228, 0.5);
                border-radius: 26px;
                padding-top:15px;
                padding-bottom: 15px;
            }

            select.profile-form__fields {

            }

            .profile-form-limit {
                position: relative;
                .profile-form__limit-indicator {
                    position: absolute;
                    bottom:15px;
                    right:17px;
                    background-color: $color-white;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    color: $color-subtle-light-grey;

                }
            }

            .profile-form__fields__checkbox {
                cursor: pointer;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                color: $color-dark-grey;
                user-select: none;
                .profile-form__fields__checkbox-box {
                    border: 1px solid $color-black;
                    border-radius: 4px;
                    width: 15px;
                    height: 15px;
                    margin-right: 10px;
                    display: inline-block;
                    vertical-align: middle;
                    line-height: 13px;
                    text-align: center;
                    svg {
                        width:10px;
                        opacity: 0;
                        transition: opacity 300ms ease;
                        path {
                            stroke-width: 3px;
                            stroke: $color-white;
                        }
                    }
                }
                
                input[type="checkbox"] {
                    display: none;
                    &:checked + .profile-form__fields__checkbox-box {
                        background: $color-black;
                        svg {
                            opacity: 1;
                        }
                    }
                }
            
            }
            
        }

        &__actions {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            .cancel_btn {
                background: $color-white;
                margin-right:25px;
                .cn__button__title {
                    color: $color-cn-pink;
                }
            }
            .cn__button {
                height: 46px;
                width: 210px;
                max-width: 50%;
                border-radius: 30px;
                &:disabled {
                    opacity: 0.5;
                    cursor: not-allowed;
                }
                .cn__button__title {
                    margin-left: 0px;
                }
            }
        }  
        
        
        .profile-form__verification_models {
            .modelComponent_content {
                max-width: 396px;
                width:100%;
            }
        }
        .profile-form__verification_models_inner_content {
            .profile-form__modelError {
                background: $color-pale-pink;
                width:100%;
                padding:6px 8px;
                margin-top: 10px;
                color: $color-bright-red;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                border-radius: 8px;
                font-size: 12px;
                svg {
                    margin-right: 10px;
                    path {
                        fill: $color-bright-red;
                    }
                }
            }
            .profile-form__verification_models_inner_content_title {
                text-align:center;
                h5 {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    text-align: center;
                }

                .profile-form__verification_models_inner_content_button {
                    margin-top: 30px;
                    
                    button {
                        background-image: linear-gradient(92.37deg, #F35597 1.81%, #7868C7 148.68%);
                        border-radius: 30px;
                        text-transform: initial;
                        color: var(--color-white);
                        font-weight: 600;
                        font-size: 16px;
                        padding-top: 11px;
                        padding-bottom: 11px;
                        text-transform: none;
                        max-width: 245px;
                        width:100%;
                        display:block;
                        margin:0px auto;
                        height:45px;
                        border:none; outline: none;
                    }
                }
            }
            .profile-form__verification_models_inner_content_description {
                text-align:center;
                max-width: 280px;
                margin-left:auto;
                margin-right:auto;
                
                button {
                    background: transparent;
                }

                p {
                    font-size: 16px;
                    color:$color-black; 

                    span {
                        font-size: 14px;
                    }
                }
                button {
                    appearance: none;
                    text-decoration: underline;
                    font-size: 14px;
                    color:$color-black; 
                    cursor: pointer;
                    border:none;
                    outline:none;
                }
            }
            .profile-form__verification_models_inner_content_input_recaptcha {
                display: flex;
                justify-content: center;
            }
            .profile-form__verification_models_inner_content_input {
                align-items: center;
                display:flex;
                position: relative;
                justify-content: center;
                flex-direction: column;

                

                .profile-form__verification_models_inner_content_otpInput {
                    text-align: center;
                    button {
                        color: var(--color-white);
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 150%;
                        min-width: 245px;
                        max-width:100%;
                        padding-top: 11px;
                        border-radius: 30px;
                        padding-bottom: 11px;
                        margin:40px 0px;
                        text-transform: none;
                        background-image: linear-gradient(92.37deg, #F35597 1.81%, #7868C7 148.68%);
                        &:disabled {
                            background:var(--color-field-grey);
                            color: rgba(0, 0, 0, 0.26);
                            cursor: not-allowed;
                        }
                    }
                    .profile-form__verification_models_inner_content_otpInputs_style {
                        width:60px;
                        max-width:60px;
                        height:60px;
                        margin:0px 10px;
                        border: 1px solid #ACABB4;
                        border-radius: 10px;
                        padding:10px;
                        input {
                            width:100% !important;
                            &:focus, &:active {
                                outline: none;
                                border: none;
                            }
                        } 
                    }
                }
                .profile-form__verification_models_inner_content_customInput {
                    position: relative;
                    width:100%; 
                    
                    input.profile-form__fields, .phone-input__input {
                        padding-right:50px;
                    }
                    button {
                        background-color: $color-slate-grey;
                        width: 32px;
                        height:32px;
                        border-radius: 32px;
                        border:none;
                        outline:none;
                        cursor: pointer;
                        line-height: 32px;
                        text-align: center;
                        position: absolute;
                        right:7px;
                        top:7px;
                        svg {
                            height:8px;
                            width: 8px;
                            path {
                                stroke: $color-white;
                            }
                        }
                        &:not(:disabled) {
                            background-color: $color-primary;
                            
                            &:hover {
                                background-color: $color-primary-hover;
                            }
                        }
                    }
                }
            }
            
        }
    }

.profile-form__recaptcha {
    display: flex;
    place-content: center;
}