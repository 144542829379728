@import "../tools/mixins";

//------------------------ DESKTOP ------------------------//
.cn__checkout {
  position: absolute;
  background: #ffffff;
  display: table;
  width: 100%;
  height: 100%;
  .additional_width {
    width: 139px;
  }
  .cn__checkout__tryagin {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 10px;
    @include mobile {
      margin-right: 10px;
    }
    .cn__checkout__tryagin__paynow_button {
      width: 296px;
      height: 44.741px;
      padding: 10px 30px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      color: #FFF;
      border-radius: 30px;
      background: linear-gradient(92deg, #F35597 1.79%, #7868C7 99.81%);
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      line-height: 150%;
      letter-spacing: 0.16px;
      cursor: pointer;
      @include mobile {
        margin-right: 30px;
      }
    }
    .cn__checkout__tryagin__title {
      color: #000;
      font-size: 16px;
      font-weight: 600;
      line-height: 150%;
      letter-spacing: 0.16px;
      margin-bottom: 15px;
    }
    .cn__checkout__tryagin__paynow {
      margin-top: 20px;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  &__wrapper {
    position: relative;
    max-width: 1440px;
    // width: 100vw;
    width: 100%;
    padding: 0 50px;
    margin: 76px auto 0;
  }

  // -------- Header
  .header {
    .max-width-wrapper {
      max-width: 1440px;
    }
    //menu options
    .li--blog {
      display: none !important;
    }
  }

  // -------- Main
  &__main {
    position: relative;
    width: 100%;
    display: grid;
    // grid-template-columns: 50% 50%;
    grid-template-columns: calc(50vw - 40px) 1fr;
    grid-column-gap: 2.084vw;
    // grid-column-gap: 20px;
    padding-bottom: 40px;

    &__sec1 {
      height: auto;
    }

    //----- phone input
    .PhoneInputCountryIcon--border {
      border-radius: 50%;
      box-shadow: none;
      overflow: hidden;
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
    .PhoneInputCountryIcon--border img {
      object-fit: cover;
    }
    .PhoneInputCountrySelectArrow {
      display: none;
    }
    .PhoneInputInput {
      outline: none;
    }
    .PhoneInput {
      input {
        font-size: 14px;
        color: #242526;
        height: auto;
        &::placeholder {
          color: #acabb4;
        }

        &:focus-visible,
        &:focus-within {
          outline: none;
        }
      }
    }

    //---- Datepicker
    .SingleDatePicker,
    .SingleDatePickerInput {
      width: 100%;
      background: transparent;
    }
    .SingleDatePickerInput__withBorder {
      border-radius: unset;
      border: unset;
    }
    .DateInput {
      width: 100%;
    }
    .DateInput_input_1 {
      position: relative;
      width: 100%;
      height: auto;
      background: #ffffff;
      border: 0.069vw solid #dfe2e5;
      box-sizing: border-box;
      box-shadow: 0px 0.347vw 2.084vw rgba(231, 228, 228, 0.5);
      border-radius: 2.779vw;
      padding: 0.73vw 1.598vw;
      font-size: 0.972vw;
      line-height: 1;
      letter-spacing: 0.01em;
      transition: border 0.3s ease;
      color: #242526;
      height: 3.1945vw;

      &::placeholder {
        color: #acabb4;
      }

      &:focus-visible,
      &:focus-within {
        outline: none;
      }

      &:focus {
        border-color: #000000;
      }
    }
    .DateInput_fang_1 {
      display: none;
    }
    .SingleDatePicker_picker {
      top: 3.8vw !important;
    }
    .DayPicker__withBorder {
      border-radius: 1.4375rem;
      box-shadow: rgb(0 0 0 / 15%) 0px 16px 32px;
      overflow: hidden;
    }
    .CalendarDay__selected,
    .CalendarDay__selected:active,
    .CalendarDay__selected:hover {
      background: #e86993;
      border-radius: 12px;
    }
    .CalendarDay__blocked_out_of_range:hover {
      color: #acabb4 !important;
    }
    .CalendarMonth_caption {
      select {
        height: auto;
        padding: 0;
        color: #242526;
        font-size: 16px;
        font-weight: bolder;
        cursor: pointer;
        box-sizing: content-box;
        text-align: center;
        &:focus {
          outline: none;
        }
        &:first-child {
          margin-right: 2px;
        }
      }
    }
    .DayPicker_transitionContainer {
      box-sizing: content-box;
      padding-bottom: 2.8125rem;
    }
  }

  // -------- Button
  &__ui__button {
    .loader_circle {
      width: 1vw;
      height: 1vw;
      padding: 0.15vw;
      .loader_circle-core {
        background: linear-gradient(92.14deg, #f35597 1.79%, #f53959 99.81%);
      }
    }
    &.cbutton__transparent {
      background: transparent;
      color: #f35597;
      font-weight: 400;

      .loader_circle {
        .loader_circle-core {
          background: #f7f7f7;
        }
      }
    }
  }

  // -------- Booking Deatails
  .cn__checkout__bookingdetails {
    .simplebar-track.simplebar-vertical {
      height: calc(100% - (1.389vw * 2));
      top: 1.389vw;
      width: 0.694vw;
      right: 0vw;




      &::after {
        position: absolute;
        content: "";
        top: 0;
        width: 0.138vw;
        height: 100%;
        // background: #e9e9eb;
        background: transparent;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 0.347vw;
        z-index: -1;
      }

      .simplebar-scrollbar:before {
        // background: rgba(244, 102, 162, 0.8);
        background: #E9E9EB;
      }
    }
  }

  .cn__checkout__bookingdetails__payment__extra {
    border-top: 1px solid #e9e9eb;
    padding-top: 2.084vw;
  }

  // ----- Bottom Component
  .cn__checkout__bottom {
    .cbutton__transparent {
      // padding: 0;
      margin-right: 2.779vw;
    }
  }

  // ------- Footer
  .cn__shortfooter__wrapper {
    border-top: 1px solid #ededed;
    .cn__shortfooter {
      max-width: 1440px;
      padding: 30px 50px;
    }
  }
}

@media (min-width: 1441px) {
  .cn__checkout {
    // -------- Main
    &__main {
      position: relative;
      width: 100%;
      display: grid;
      grid-template-columns: 671px 639px;
      grid-column-gap: 30px;

      &__sec1 {
        height: auto;
      }
    }

    // -------- Button
    &__ui__button {
      .loader_circle {
        width: 15px;
        height: 15px;
        padding: 2px;
      }
    }

    // -------- Stripe
    .__PrivateStripeElement {
      height: 100% !important;
    }

    //----- phone input
    .PhoneInput {
      input {
        font-size: 14px;
      }
    }

    //---- Datepicker
    .SingleDatePicker_picker {
      top: 55px !important;
    }
    .DateInput_input_1 {
      border: 0.5px solid #dfe2e5;
      box-shadow: 0px 5px 30px rgb(231 228 228 / 50%);
      border-radius: 100px;
      padding: 11px 23px;
      font-size: 14px;
      height: 46px;
    }

    // -------- Booking Deatails

    .cn__checkout__bookingdetails__payment__extra {
      padding-top: 30px;
    }

    // ----- Bottom Component
    .cn__checkout__bottom {
      .cbutton__transparent {
        margin-right: 60px;
      }
    }
  }
}
//------------------------ TABLET/MOBILE ------------------------//
@include tablet {
  .cn__checkout {
    &__wrapper {
      padding: 0 30px;
      margin: 76px auto 0;
    }
    // ------- Footer
    .cn__shortfooter__wrapper {
      .cn__shortfooter {
        padding: 30px 30px 30px;
      }
    }
  }
}
//------------------------ MOBILE ------------------------//
@include mobile {
  .cn__checkout {
    overflow: hidden;

    &__wrapper {
      padding: 0;
      margin: 0 auto;
    }
    // -------- Main
    &__main {
      position: relative;
      width: 100%;
      display: block;
      padding-bottom: 0;
      // padding-bottom: 20px;

      //----- phone input
      .PhoneInputCountryIcon--border {
        width: 6.5vw;
        height: 6.5vw;
      }
      .PhoneInput {
        input {
          font-size: 3.74vw;
        }
      }
    }
    //---- Datepicker
    .DateInput_input_1 {
      border: 0.266vw solid #dfe2e5;
      box-shadow: 0 1.334vw 8vw rgb(231 228 228 / 50%);
      border-radius: 10.666vw;
      margin-top: 2.67vw;
      padding: 3.2vw 5.334vw;
      font-size: 3.74vw;
      height: 10.748vw;
    }
    .SingleDatePicker_picker {
      top: 15vw !important;
      left: 50% !important;
      transform: translateX(-50%);
    }
    .DayPicker__withBorder {
      border-radius: 1.4375rem;
      box-shadow: rgb(0 0 0 / 15%) 0px 16px 32px;
      overflow: hidden;
    }
    .CalendarDay__selected,
    .CalendarDay__selected:active,
    .CalendarDay__selected:hover {
      background: #e86993;
      border-radius: 12px;
    }
    .CalendarDay__blocked_out_of_range:hover {
      color: #acabb4 !important;
    }
    .CalendarMonth_caption {
      select {
        height: auto;
        padding: 0;
        color: #242526;
        font-size: 16px;
        font-weight: bolder;
        cursor: pointer;
        box-sizing: content-box;
        text-align: center;
        &:focus {
          outline: none;
        }
        &:first-child {
          margin-right: 2px;
        }
      }
    }
    .DayPicker_transitionContainer {
      box-sizing: content-box;
      padding-bottom: 2.8125rem;
    }
    // -------- Booking Deatails
    .cn__checkout__bookingdetails__payment__extra {
      padding-top: 8vw;
    }
    // ----- Bottom Component
    .cn__checkout__bottom {
      .cn__checkout__ui__button {
        font-family: var(--primary-font);
        // width: auto;
        // padding: 10px;
        padding: 0;
        margin-top: 0;
        width: 100%;
        min-width: unset;
        height: 48px;
        border-radius: 11px;
        border: 0.5px solid #797882;
        color: var(--color-pure-black);
        background: none;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        letter-spacing: 0.16px;

        &--dark {
          background-color: var(--color-pure-black);
          color: white;
        }
      }
      .cbutton__transparent {
        padding: 0;
        margin-right: 10vw;
      }
    }
    // ----- RECEIPT
    .cn__checkout__receipt {
      .cn__checkout__bookingdetails__payment {
        padding: 0;
        border: none;
        background: unset;
        box-shadow: none;
      }
    }
    // ------- Footer
    .cn__shortfooter__wrapper {
      .cn__shortfooter {
        padding: 30px 20px 30px;
      }
    }
  }
}

/* Checkout Date Picker Override */
.MuiDatePicker {
  * {
    font-family: var(--primary-font) !important;
  }
  .MuiPaper-root {
    border-radius: 20px;
  }
  .MuiCalendarPicker-root {
    .MuiYearPicker-root {
      .PrivatePickersYear-root {
        .PrivatePickersYear-yearButton {
          &.Mui-selected {
            background-color: var(--color-cn-pink);
          }
        }
      }
    }

    .MuiPickersDay-root {
      border-radius: var(--radius-calendar);
      &.Mui-selected {
        background-color: var(--color-cn-pink);
      }
    }
  }
}

body {
  &.backdrop-loading {
    overflow: hidden;
  }
}

.__PrivateStripeElement {
  width: 100%;
  height: 100%;
}
