.page-listings {
    .room-types.product-section:not(.product-section--anchored) {
        padding-top: 20px;
        margin-top: 26px;
    }
}

.room-types {
    .room-types_slider {
        .room-card_room-main{
            margin-top: 14px;
            @media (max-width: 600px) {
                gap: 10px;
            }
        }

        .room-card_room-main-info_unit{
            margin-top: 0;

            &:last-child{
                margin-left: 20px;
            }
        }

        .room-card_room-price-info_container{
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            @media (max-width: 600px) {
                align-items: flex-end;
            }
        }

        .property-card_free-cancellation-sticker{
            top: 15px;
            right: 15px;
            position: absolute;
            background-color: $color-sea-green;
            color: $color-white;
            border-radius: 11.125rem;
            svg {
                path {
                    fill: $color-white;
                }
            }
        }
    }
}

.room-types {
    .room-types_room-slider {
        .simplebar-content-wrapper {
            scroll-snap-type: x mandatory;

            @media (min-width: 600px) {
                scroll-snap-type: initial;
            }

            .cn__slider__cards {
                overflow: initial;

                .room-card_room-main{
                    margin-top: 14px;
                    @media (max-width: 600px) {
                        gap: 10px;
                    }
                }

                .room-card_room-main-info_unit{
                    margin-top: 0;

                    &:last-child{
                        margin-left: 20px;
                    }
                }

                .room-card_room-price-info_container{

                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;

                        @media (max-width: 600px) {
                            align-items: flex-start;
                        }


                }
            }
            .room-card_room-container{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }
        }

        .cn__slider__arrows {
            display: none;

            @media (min-width: 600px) {
                display: flex;
            }
        }

        .room-types_room-slider--arrows-hidden {
            display: none;
        }

        .property-card_free-cancellation-sticker{
            top: 15px;
            right: 15px;
            position: absolute;
            background-color: $color-sea-green;
            color: $color-white;
            border-radius: 11.125rem;
            svg {
                path {
                    fill: $color-white;
                }
            }
        }
    }

    .room-types_action {
        color: $color-black-gun-metal;
        background: var(--color-white);
        font-family: var(--primary-font);
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.16px;
        left: 50%;
        border-radius: 30px;
        border: 1px solid  $color-black-gun-metal;
        transform: translateX(-50%);
        &:hover {
            background-color: unset;
            border: 1px solid $color-dark-grey;
            color: $color-dark-grey;
        }
        @media (max-width: 600px) {
            left:0;
            transform:none;
        }
    }

    // .swiper-container {
    //     .swiper-wrapper {
    //         .swiper-slide {
    //             width: auto;
    //         }
    //     }
    // }
}

.room-card {
        span {
            .disclaimer_tooltip + div {
                margin-left: -5px !important;
            }
    }
    .radiobox_label {
        label {
            padding-left: 0px !important;
            padding-bottom: 3px !important;
            padding-top: 3px !important;
        }
    }
    .swiper-container {
        border-top-left-radius: 15px !important;
        border-top-right-radius: 15px !important;

        .swiper-wrapper {
             .swiper-slide {
                width: 100% !important;
            }
        }
    }
    .property-card_free-cancellation-sticker{
        background-color: $color-sea-green;
        color: $color-white;
        border-radius: 11.125rem;
        svg {
            path {
                fill: $color-white;
            }
        }
    }
    *{
        font-family: $primary-font !important;
        .property-card_discountchip {
            display: flex;
            justify-content: flex-end;
            position: absolute;
            -moz-box-pack: justify;
            pointer-events: none;
            bottom: 21px;
            right: 0px;
            svg {
              position: absolute;
            }
            .additional_width {
                width: 152px;
            }
            span {
              z-index: 99;
              width: 132px;
              color: #FFF;
              line-height: 150%;
              display: flex;
              flex-direction: column;
              padding-top: 3px;
              .savetext {
                font-size: 12px;
                font-weight: 400;
                letter-spacing: 0.12px;
                text-align: center;
                margin: 0px;
                height: 11px;
              }
              .pricetext {
                font-size: 14px;
                font-weight: 600;
                letter-spacing: 0.14px;
                text-align: center;
              }
            }
          }
    }
    &.cn__card {
        height: 100%;
        background-color: var(--color-white);
        justify-content: unset;
        width: 300px;
        border: 1px solid $color-light-gray-input;
        box-shadow: unset;
        @media (max-width: 600px) {
            width: unset;
        }
        @media (min-width: 1681px) {
            width: 325px !important
        }
        // box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12), 0px 10px 32px -4px rgba(24, 39, 75, 0.1);
    }

    .MuiIconButton-root{
        padding: 0px;
        &:hover{
            background-color: transparent;
        }
        .MuiIconButton-label{
            circle{
                fill: var(--color-green);
            }
        }
    }

    &.room-card--horizontal {
        display: grid;
        grid-template-columns: 40% 60%;
        border-radius: 15px;
        width: unset;
        min-height: 219px;
        @media (min-width: 1681px) {
            width:unset !important;
        }
        .radiobox_label {
            label {
                padding-left: 10px !important;
                padding-bottom: 10px !important;
                padding-top: 10px !important;
            }
        }
        .swiper-container {
            border-top-right-radius: unset !important;
        }
        .room-card_media {
            padding: 0;
            height: 100% !important;
            border-top-right-radius: initial;
            border-bottom-left-radius: 15px;
        }

        .room-card_room-price-info {
            flex-direction: column;
        }

        .room-card_room-action {
            flex-direction: column-reverse;
            text-align: center;
            padding-right: 0;
            padding-bottom: 0;
            width: auto;
            position: relative;
            bottom: unset;

            .room-card_room-book-action {
                margin-top: 15px;
                margin-bottom: 10px;
            }
        }

        .room-card_content{
            padding-bottom: 0;
            margin-bottom: 16px;
        }

        .room-card_room-main{
            margin-top: 13px;
        }

        .room-card_room-main-info_unit{
            &:last-child{
                margin-top: 0;
                margin-left: 20px;
            }
        }
    }

    &.room-card-new--horizontal {
        position: relative;
        display: flex;
        flex-direction: row;
        
        span:has(.react-loading-skeleton) {
            width: 264px !important;
            height: 100% !important;
        }

        .react-loading-skeleton{
            width: 264px  !important;
            height: 100% !important; 
        }
    
        .swiper-container {
            width: 264px;
            height: 100% !important;
            @media (max-width: 780px) {
               width: 181px;
            }
        }  
    }
    

    &.room-card--short {
        // min-width: 335px;

        .room-card_room-main {
            margin-top: 0;
        }
    }

    .room-card_content {
        background-color: var(--color-white);
        margin-bottom: 71px;
    }

    .room-card_title-wrapper {
        display: flex;
    }
    .room-card-disclaimer{
        // margin-right: auto;
        // margin-left: 5px
    }
    .room-card_title {
        color: var(--color-black);
        white-space: unset;
    }
    .room-card_title-tag {
        flex: 0 0 auto;
    }
    .room-card_title-tag-inner {
        border-radius: 4px;
        padding: 4px;
        background-color: var(--color-shade-gray);
        display: inline-block;
        color: var(--color-shade-black);
        font-size: 14px;
        line-height: 21px;
        height: 29px;
    }
    .room-card_room-containerheadlines {
        display: flex;
        justify-content: space-between;
    }
    .room-card_room-containerheadlines-notavailable {
        color: #F35597;
        font-weight: 500;
        font-size: 14px;
        line-height: 15.82px;
        display: flex;
        align-items: center;
        gap: 5px;
        max-width: 233px;
        width: 230px;
        min-width: 138px;
        justify-content: flex-end;
    }
    .room-card_room-headlines_unit {
        color: var(--color-black);
        font-size: 14px;
        column-gap: 5px;

        @media (max-width: 600px) {
            color: var(--color-dark-grey);
            font-weight: 500;
            font-size: 12px;
        }

        svg {
            transform: scale(0.8);
        }
    }

    .room-card_room-main-info_unit {
        color: var(--color-black);

        @media (max-width: 600px) {
            margin-top: 14px !important;
        }

        &:first-child{
            @media (max-width: 600px) {
                margin-top: 0 !important;
            }
        }

    }

    .room-card_room-price-nightly {
        color: var(--color-slate-grey);
        text-align: left;
        font-weight: 600;
        font-size: 16px;
        .room-card_night_price{
            font-size: 16px;
        }
        .room-card_night{
            color:var(--color-dark-grey);
            font-weight: 400;
            font-size: 14px;
        }
    }

    .including_taxes{
        color:var(--color-dark-grey);
        margin-top: 3px;
        font-size: 13px;
        font-weight: 500;
        line-height: 150%; 
        letter-spacing: 0.12px;
    }

    .room-card_room-main-info_more {
        color: var(--color-black);

        @media (max-width: 600px) {
            font-size: 12px;
        }

        *{
            font-family: $primary-font !important;
        }

        .carret-icon{
            width: 10px;

            path {
                stroke: var(--color-black);
            }
        }
    }

    .room-card_room-price-total {
        color: var(--color-pure-black);
        justify-content: flex-end;
        font-weight: 600;
        font-size: 18px;
        gap: 6px;
        .room-card_room-price-total_icon{
            cursor: pointer;
        }
    }

    .room-card_room-book-action {
        background: #F35597;
        color: var(--color-white);
        font-family: var(--primary-font);

        &.room-card_room-book-action--disabled {
            background: var(--color-subtle-white);
            color: var(--color-dark-grey);
            width: 230px;
        }
    }

    .room-card_room-action {
        color: var(--color-dark-grey);
        position: absolute;
        bottom: 0;
        width: 100%;
    }

    .room-card_room-action_loader {
        color: var(--color-white);
    }

    .room-card_room-availability {
        color: var(--color-red);
    }

    .room-card_room-main-info_unit_name {
        display: -webkit-box;
        line-clamp: 2;
        box-orient: vertical;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: initial;
        overflow: hidden;
        text-transform: capitalize;
    }
    .room-card_room-main-info_unit_freecancellation {
        color: var(--color-sea-light-green);
    }
    .room-card_room-main-info_unit_freecancellationsvg path{
        fill: var(--color-sea-light-green);
    }
}

.room-types--dummy {
    position: relative;
}
.cn__card-dummy {
    pointer-events: none;
    filter: blur(9px);
    .room-card_room-price-info {
        .room-card_room-price-info_container {
            visibility: hidden;
            opacity: 0;
        }
        // visibility: hidden;
        // opacity: 0;
        pointer-events: none;
    }
    &--blur {}
}

.room-details {
    position: relative;
    .MuiPaper-root {
        overflow-x: hidden;
    }
    .room-details_room-info_head {
        border-bottom: 1px solid var(--color-light-grey);
        @media (max-width: 600px) {
            margin-bottom: 30px;
        }
    }
    .room-details_media-block{
        display: flex;
        flex-direction: column;
        align-items: center;
        @media (max-width: 600px) {
            width: 100vw;
        }
        @media only screen and (min-width: 600px) and (max-width: 1136px) {
            width: 95vw;
        }
    }
    @media (max-width: 600px) {
        .room-details_content-area {
            padding-left: 0;
            padding-right: 0;
            margin-top: 0;
        }

        .room-details_media-block_img {
            border-radius: initial;
        }

        .room-details_room-info,
        .room-details_room-options {
            padding-left: 20px;
            padding-right: 20px;
            width: 100vw;
            padding-top: 20px;
            margin-top: 0px
        }

    }


    @media (max-width:600px){

        .room-details_room-info_main_container {
            width: 100%;
            .product-amenities-group{
                margin-top: 15px;
                margin-top: 15px;
                display: grid;
                grid-gap: 1rem;
                grid-template-columns: repeat(2, minmax(min-content, 1fr)) !important;
                div {
                    border: none;
                    span{
                        font-size: 12px;
                        font-weight: 400;
                }
            }
        }
        .room-card_room-headlines_divider{
            display: none;
        }
        .room-card_room-headlines_title {
            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
            letter-spacing: 0.01em;
            color: var(--color-black);;
            margin-top: 15px;
        }
    }
}
    @media (min-width:600px) {
        .room-details_room-info {

            width: 90%;
            max-width: 800px;
            margin-top: -55px;
            border-radius: 15px;
            box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12), 0px 10px 32px -4px rgba(24, 39, 75, 0.1);
            background-color: var(--color-white);
            display: flex;
            position: relative;
        }
      .room-details_room-info_main_container {
            width: 100%;
            .product-amenities-group{
                display: flex;
                flex-wrap: wrap;
                row-gap: 20px;
                div {
                    border: none;
                    span {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 150%;
                        color: var(--color-dark-grey);
                    }
                }
            }
        }
        .room-card_room-headlines_divider{
            border: 0.5px solid #E9E9EB;
        }
        .room-card_room-headlines_title {
            display: none;
        }
        .room-details_room-info_head {
            border-bottom: none;
            display: flex;
            width: 100%;
            justify-content: space-between;
            padding-bottom: 0px;
        }
    }

    .room-card_room-info-headlines {
        color: var(--color-dark-grey);
        font-family: var(--primary-font);
        margin-top: 0px;
    }

    .room-details_room-options_scrollable {
        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-track {
            background-color: var(--color-shade-gray);
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--color-subtle-grey);
            border-radius: 2px;
        }
    }
}

.room-details_modal {
    &.guest-selector_modal {
        .guest-selector_modal-overlay {
            z-index: 5;
            .guest-selector_modal-content {
                overflow: auto;
                background-color: var(--color-subtle-white);
                height: 100%;
                &:not(.ReactModal__Content--after-open) {
                    transform: translateY(100%);
                }
            }
        }
    }
}

.all-rooms {
    .amenities-collection {
        .amenities-chip {
            &.room-amenity-chip {
                background-color: var(--color-white);
                border-color: var(--color-field-grey);
                transition: .3s;
                .amenities-chip_close-btn {
                    display: none;
                    opacity: 0;
                    animation: appear .3s forwards;
                }

                &.room-amenity-chip__active {
                    .amenities-chip_close-btn {
                        display: initial;
                    }
                    background-color: var(--color-champagne-pink);
                }
            }
            margin-top: 0;
        }
        .swiper-container.swiper-container-horizontal {
            margin-left: initial;
            margin-right: initial;
            width: 100%;
            margin: initial;

            .swiper-button-next,
            .swiper-button-prev {
                color: var(--color-black);
                outline: 0;

                &::after {
                    font-weight: bold;
                    font-size: initial;
                    background: var(--color-subtle-white);
                    box-shadow: 0 0 20px 30px var(--color-subtle-white);
                }

                &.swiper-button-disabled {
                    opacity: 0;
                    color: var(--color-black);
                }
            }

            .swiper-button-prev {
                transform: translateX(-12px);
            }

            .swiper-button-next {
                transform: translateX(12px);
            }

            .swiper-wrapper {
                .swiper-slide {
                    width: fit-content;
                }
            }
        }
    }
}

.all-rooms_toolbar {
    &.pane-toolbar {
        z-index: 99999;
        @media (max-width: 649px) {
            height: initial;
        }
    }
}

.all-rooms_toolbar-title {
    color: var(--color-black);
}

.room-card_title,
.room-details_room-info-title,
.room-details_room-options_title span {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-width: 100vw;
}


.room-types--dummy .room-card-new--horizontal {
    height: 280px;
    overflow: hidden;
}

.all-rooms-white {
    .amenities-collection {
        .swiper-container.swiper-container-horizontal {

            .swiper-button-next,
            .swiper-button-prev {
                color: var(--color-black);
                outline: 0;

                &::after {
                    background: var(--color-white);
                    box-shadow: 0 0 20px 30px var(--color-white);
                }

                &.swiper-button-disabled {
                    opacity: 0;
                    color: var(--color-black);
                }
            }
        }
    }
}