.floating-input {
    position: relative;
    width: 100%;
    display: flex;
    // height: rem(38);
    border-radius: rem(6);
    border: none;
    font-size: inherit;
    &.floating-input__with-validation {
        .floating-input_validation-payload {
            padding: rem(3) 0;
            font-size: small;
            line-height: rem(13);
            color: #F26659;
            white-space: nowrap;
        }
    }
    .floating-input_input {
        position: inherit;
        width: inherit;
        height: inherit;
        border-radius: inherit;
        border: inherit;
        font-size: inherit;
        outline: none;
        background-color: $color-light-gray-input;
        padding: 0 rem(15);
        transition: border .15s ease;
        &.floating-input_input__dirty {
            background-color: #F7F7F7;
            border: rem(1) solid #ACABB4;
            &.floating-input_input__focused {
                border: rem(2) solid #ACABB4;
            }
        }
        &.floating-input_input__focused {
            background-color: #F7F7F7;
            border: rem(2) solid #ACABB4;
        }
    }
    .floating-input_label {
        font-size: 1rem;
        position: absolute;
        display: inline-block;
        left: rem(20);
        top: rem(10);
        pointer-events: none;
        z-index: 1;
        color: $color-gray-font;
        transition: .15s ease;
    }
    .floating-input_input_clear-btn {
        animation: appear .3s ease-in-out forwards;
        opacity: 0;
        border: none;
        outline: none;
        display: flex;
        justify-content: center;
        align-items: center;
        right: rem(13);
        cursor: pointer;
        background-color: $color-light-gray-input;
        border-radius: 50%;
        width: rem(24);
        height: rem(24);
        .floating-input_input_clear-btn_icon {
            width: rem(10);
            height: rem(10);
            path {
                stroke: #797882;
                stroke-width: rem(2);
            }
        }
    }
    .floating-input_input.floating-input_input__focused+.floating-input_label,
    .floating-input_input.floating-input_input__dirty+.floating-input_label {
        top: rem(-8);
        left: rem(11);
        font-size: rem(12);
        padding: rem(2) rem(4);
        border-radius: rem(3);
    }
    .floating-input_input.floating-input_input__dirty+.floating-input_label {
        background-color: #f7f7f7;
        color: $color-dark-grey;
    }
    .floating-input_input.floating-input_input__focused+.floating-input_label {
        background-color: #F7F7F7;
    }
}