@charset 'UTF-8';
// 1. Settings
@import
  '../settings/colors',
  '../settings/font',
  '../settings/responsive',
  '../settings/variables',
  '../settings/z-index',
  '../tools/mixins';

// 6. Components
@import
  '../components/header',
  '../components/banner-text-form.scss',
  '../components/tabs-panel.scss',
  '../components/Tabs',
  '../components/SearchInput',
  '../components/AmenitiesChip',
  '../components/DropDown',
  '../components/ActionsBar',
  '../components/ButtonGroupCounter',
  '../components/Radio',
 '../components/FloatingInput',
 '../components/Checkbox',
 '../components/Category';
  //'../components/footer';


  @import
  '../components/filters-side-bar/FiltersDatePicker',
  '../components/filters-side-bar/GuestSelector';

    // Here is styles according to search results
    @import '../components/SearchResults/common';

  @import
  '../components/SearchResults',
  '../components/ShowMapTrigger',
  '../components/SortBySelector',
  '../components/DualCaret',
  '../components/PropertyCard',
  '../components/AsidePropertyDetail',
  '../components/Loader';


  @import '../generic/global/tabs-panel/tabs-panel.scss';

body {
  height: 100%;
  @media screen and (max-width: 600px)  {
    padding-bottom: 0;
  }
}

.scroll-none {
  .search-results {
    &.search-results_mobile, &.search-results_tablet {
      .mobile-tab-action {
        z-index: 0;
      }
    }
  }
}

.search-results_lock-screen, .ReactModal__Body--open {
  overflow: hidden;
  height: 100%;
}

.ReactModal__Body--open {
  .mobile-tab-action-portal {
    visibility: hidden;
  }
}

.popular-filters-portal {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1200;
}


.map-container.map-ready {
  .search-results-map {
    .gm-style-iw-a {
      animation: appear .3s ease-in-out forwards;
      .gm-style-iw.gm-style-iw-c {
        padding: rem(7);
        .gm-style-iw-chr {
          max-height: 7px;
          button, span {
            margin: initial !important;
            width: 17px !important;
            height: 17px !important;
          }
        }
      }
    }
  }
}

.search-results-page{

  .react-tiny-popover-container {
    z-index: 1201;
  }

  .search-results.search-results_desktop.search-results_desktop_default {
    display: block;
    .scrolled {
      border-bottom: 1px solid #D8D8DA;
    }

    .search-results-map {
      .map-container {
        margin-top: rem(15);
      }
    }

    .main.main_desktop.main_desktop_default {
      .info-banner-bar.info-banner-bar--desktop {
        padding-top: rem(15);
      }
    }
  }

  @include tablet{
    .MuiDrawer-paper{
      overflow: unset !important;
      .DayPicker__withBorder{
        box-shadow: 0 0.125rem 0.625rem rgb(0 0 0 / 15%);
      }
    }
  }

}

.search-results {
    //Cookie banner
    .cn__cookie-wrapper{
      .cn__cookie{
          box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12);
          outline: 1px solid #DFE2E5;
      }
    }
    .map-container {
      max-width: unset;
      .gm-bundled-control .gmnoprint {
          display: block;
      }

      .gmnoprint:not(.gm-bundled-control) {
          display: none;
      }

      .gm-style-cc {
          right: 0;
      }
    }
    /* &.search-results_desktop,
    &.search-results_tablet {
      .map-container {
        &.listing-details--map-container {
          .map-control {
            &.map-fullscreen {
              display: none;
            }
          }
        }
      }
    }
    &.search-results_mobile, ->LDP */
    &.search-results_desktop,
    &.search-results_tablet {
      /*.map-container {
        &.map-search__found {
          .map-control {
            &.map-pin {
              width: initial;
              min-width: initial;
              .map-pin-icon {
                margin-left: rem(5);
                margin-right: rem(5);
              }
            }
          }
        }
        .map-control {
          width: rem(36);
          height: rem(36);
          border-radius: rem(10);
          &.map-reset-btn{
            display: none;
          }
          &.map-pin {
            top: rem(92);
            min-width: rem(36);
            min-height: rem(36);
            .map-pin-icon {
              margin: auto;
            }
          }
          &.map-viewtype {
            top: rem(140);
          }
          &.map-fullscreen {
            &.map-fullscreen__active {
              border-width: rem(4);
            }
          }
        }*/
        /*&.map-search__search {
          .search_container {
            top: rem(112);
          }
        }*/
      //}
      .filter-chip-collection {
        .filter-chip {
          // overflow-x: scroll;
          flex: 0 0 auto;
          border-radius: 16px;
          background-color: $color-champagne-pink;
          margin-top: 10px;
          padding: 7px 10px;
          // padding: 7px 7px 7px 10px;
          // margin-right: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          animation: appear .15s ease-in-out forwards;
          cursor: pointer;
          width: max-content;
          .filter-chip-text {
            flex: 1;
            font-size: 12px;
            font-weight: 400;
            line-height: 15px;
          }
          .filter-chip-close {
            flex: 0 0 auto;
            margin-left: 5px;
            height: 15px;
            width: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
              transform: scale(1.1);
            }
          }
        }
      }
      .search-results-map,
      &.search-results_tablet_map {
        .filter-chip-collection {
          .filter-chip {
            margin-top: 0;
          }
        }
      }
    }
    &.search-results_desktop {
      display: grid;
      grid-template-columns: 414px 1fr;
      /*.map-container {
        height: 100vh;
        .map-control {
          &.map-pin {
            margin-top: initial;
            top: rem(11);
          }
          &.map-viewtype {
            top: rem(44);
          }
        }
        // &.map-search__search {
        //   .search_container {
        //     top: 10%;
        //     left: 1.25rem;
        //   }
        // }
      }
      &.search-results_desktop_default {
        .map-container {
          height: auto !important;
          .map-control {
            //  &.map-pin,
            &.map-viewtype {
              display: none;
            }
          }
        }
      }*/
      // &.search-results_desktop_default {
      //   grid-template-columns: 1fr 730px;
      //   .search-results-map {
      //     height: 100vh;
      //     .map-container {
      //       position: fixed;
      //       width: calc(100% - 730px);
      //       &.map-search__found {
      //         .map-control {
      //           &.map-pin {
      //             .map-pin_place-name {
      //               max-width: rem(165);
      //             }
      //           }
      //         }
      //       }
      //     }
      //   }
      // }
      &.search-results_desktop_map_expanded {
        /*.map-container {
          .map-reset-btn {
            right: 0;
            left: initial;
            margin-right: rem(20);
          }
        }*/
        .filters-side-bar, .map-container {
          min-height: calc(100vh - 65px);
          max-height: calc(100vh - 65px);
        }
        .filters-side-bar {
          background-color: $color-white;
          .filters-side-bar_logo {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }

    // &.search-results_desktop_map_expanded {
    //   .map-container {
    //     /* .bounds-search-trigger {
    //       bottom: initial;
    //       top: rem(100);
    //     } */
    //   }
    // }

    &.search-results_tablet_map {
      // .map-container {
      //   .bounds-search-trigger {
      //     /* bottom: initial;
      //     top: 3%; */
      //   }
      // }
      .amenity-bar {
        height:0px;
        padding:0px;
        margin-left:20px;
        margin-right:20px;
        width:auto;
        margin-top: 20px;
        z-index: 1;
        .amenity-bar__openbutton {
          width: max-content;
          height: max-content;
          box-shadow: 0 0 0 0.0625rem #27364b;
          border-radius: 11px;
          display: flex;
          place-content: center;
          background: $color-white;
          outline: 0;
          border: none;
          visibility: visible;
          padding: 5px;
          .amenity-bar__openbutton__inside {
            display: flex;
            column-gap: 5px;
            svg{
              width: 12px;
              position: relative;
              left: 1px;
              margin-left: 3px;
            }
          }
        }
        .amenities-collection{
          width: calc(100vw - 194px);
          margin-top: -5px;
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        }
      }
    }

    &.search-results_mobile, &.search-results_tablet {
      .map-container {
        .gm-style-cc {
            display: none;
        }
      }
      &.search-results_mobile_map, &.search-results_tablet_map {
        overflow: hidden;
      }
      &.search-results_mobile_map {
        .mobile-map-grid {
          z-index: -2;
        }
        .mobile-map-grid, .mobile-map-grid_filter {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          // bottom: 0;
          display: grid;
          row-gap: 10px;
          grid-template-columns: 1fr auto;
          width: 85vw;
          // grid-template-rows: auto auto 1fr auto auto;
          .mobile-map-filters-bar {
            grid-column: 1;
            grid-row: 1;
            height: 58px;
            display: flex;
            align-items: center;
            .mobile-tab-action {
              width: auto;
              height: auto;
              flex: 0 0 auto;
              position: fixed;
              left: 50%;
              right: 50%;
              .active-filter-counter-container {
                margin-right: 5px;
                display: flex;
                .active-filters-counter_icon_dots {
                  margin-left: -5px;
                  margin-top: -3.5px;
                }
              }
              .active-filters-counter_label {
                color: $color-white;
                font-size: 14px;
                font-weight: 600;
              }
              &.move-to-top {
                @media screen and (max-width: 600px) {
                    bottom: 175px;
                }
              }
            }
            .mobile-map-filters {
              &::-webkit-scrollbar {
                display: none;
              }
              -ms-overflow-style: none;
              scrollbar-width: none;
              padding: 10px;
              flex: 1;
              overflow-x: scroll;
              // scroll-snap-type: x mandatory;
              display: flex;
              align-items: center;
              margin-left: 7px;
              z-index: 9;
              filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
              .filter-chip {
                overflow-x: scroll;
                flex: 0 0 auto;
                border-radius: 16px;
                background-color: $color-champagne-pink;
                padding: 7px 7px 7px 10px;
                margin-right: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                .filter-chip-text {
                  flex: 1;
                  font-size: 10px;
                  font-weight: 400;
                  line-height: 150%;
                }
                .filter-chip-close {
                  flex: 0 0 auto;
                  margin-left: 5px;
                  height: 15px;
                  width: 15px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  svg {
                    transform: scale(0.75);
                  }
                }
              }
            }
          }
          &.has-active-filters {
            width: 100vw;
            z-index: 2;
            .mobile-map-filters-bar {
              grid-column: 1/3;
              /* background-color: rgba(255, 255, 255, 0.7);
              backdrop-filter: blur(20px); */
            }
            /*.map-control {
              &.map-reset-btn {
                grid-row: 2;
                margin-top: 0;
              }
            }*/
          }
          /*.map-control {
            &.map-reset-btn {
              position: unset;
              margin: 0;
              margin-left: 22px;
              //margin-right: 16px;
              //margin-left: 16px;
              margin-top: 9px;
              grid-column: 2;
              grid-row: 1;
              width: rem(40);
              height: rem(40);
              background-color: $color-white;
              .close-icon {
                path {
                  stroke: $color-black;
                }
              }
            }
            &.map-pin {
              margin: 0;
              margin-left: 16px;
              position: unset;
              grid-column: 1;
              grid-row: 2;
              width: rem(40);
              height: rem(40);
              min-width: rem(40);
              min-height: rem(40);
              .map-pin-icon {
                margin-right: 0;
                margin-left: 0;
              }
            }
          }*/
          .mobile-tab-action {
            margin: 0;
            // margin-left: 16px;
            opacity: 1;
            position: unset;
            transform: none;
            max-width: 86px;
            max-height: 40px;
            // width: 86px;
            // height: 40px;
            grid-column: 1;
            grid-row: 1;
          }
          .map-zoom-controls {
            position: unset;
            opacity: 1;
            transform: none;
            grid-column: 2;
            grid-row: 4;
          }
          .search_container.search_container {
            width: 100%;
            max-width: 100%;
            bottom: 0;
            border-bottom-left-radius: unset;
            border-bottom-right-radius: unset;
            position: relative;
            left: unset;
            right: unset;
            transform: none;
            grid-column: 1/3;
            grid-row: 5;
            margin-top: 30%;
          }
          .map-info-container {
            margin: 0 auto;
            transform: translateX(0) translateY(20%);
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.2s ease-in-out;
            transition: transform 0.2s ease-in-out;
            // animation: appear .3s ease-in-out;
            height: 0;
            position: unset;
            grid-column: 1/3;
            grid-row: 5;
          }
        }
        .mobile-map-grid_filter {
          top: unset;
          left: unset;
          right: unset;
          display: unset;
        }
        /*.map-container {
          &.map-search__found {
            .map-control {
              &.map-pin {
                display: grid;
                grid-template-columns: max-content auto max-content;
                width: max-content;
                min-width: rem(50);
                max-width: calc(100vw / 1.5);
                .map-pin-icon {
                  margin-left: rem(5);
                  margin-right: rem(5);
                }
              }
            }
          }
        }
        .map-control {
          &.map-reset-btn {
            left: initial;
            top: rem(50);
            right: rem(16);
            width: rem(40);
            height: rem(40);
            background-color: $color-white;
            .close-icon {
              path {
                stroke: $color-black;
              }
            }
          }
          &.map-pin {
            &.move-to-bottom {
              @media screen and (max-width: 600px) {
                  top: 3.2rem;// 6%;
              }
            }
            top: rem(50);
            margin-top: 0;
            width: rem(40);
            height: rem(40);
            min-width: rem(40);
            min-height: rem(40);
            .map-pin-icon {
              margin-right: 0;
              margin-left: 0;
            }
          }
        }*/

        .mobile-tab-action {
          &.mobile-tab-action--empty {
            display: none;
          }
          display: flex;
          bottom: 3%;
          right: rem(20);
          left: initial;
          width: rem(20);
          height: rem(52);
          justify-content: center;
          align-items: center;
          .mobile-tab-action_activator-container {
            // padding-top: rem(5);
            // padding-bottom: rem(5);

            .active-filters-counter {
              display: grid;
              grid-template-columns: repeat(2, max-content);
              // column-gap: 5px;
              align-items: center;
              padding: 10px;
              background: $color-deep-purple;
              border-radius: 32px;
            }
          }
          &.map-control {
            // width: rem(40);
            // height: rem(40);
            background-color: $color-white;
            border-radius: rem(10);
            margin-top: 0;
            // left: 50%;
            .mobile-tab-action_activator-container {
              .mobile-tab-action_icon, .active-filters-counter_icon {
                width: initial;
                height: initial;
                path {
                  stroke: initial;
                }
              }
              .active-filters-counter_icon {
                path {
                  fill: none;
                  stroke-width: rem(2);
                  stroke: $color-black;
                }
              }
            }
          }
          .mobile-tab-action_activator {
            padding-bottom: 0;
          }
          .mobile-tab-action-map {
            background: unset;
          }
        }
      }
      &.search-results_tablet_default, &.search-results_mobile_default {
        .main {
          z-index: unset;
        }
        overflow: initial;
      }
      .map-container {
        min-height: rem(360);
      }
      .search-results-map {
        height: 100%;
      }
      .mobile-tab-action {
        &.mobile-tab-action--empty {
          grid-template-columns: auto;
          width: rem(100);
        }
        animation: appear .3s ease-in-out;
        position: fixed;
        z-index: 1;
        bottom: rem(20);
        left: 50%;
        transform: translateX(-50%) translateZ(0);
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        height: rem(41);
        width: 177px;
        background-color: $color-deep-purple;
        box-shadow: 0 rem(4) rem(15) rgba(0, 0, 0, .15);
        border-radius: rem(32);
        .mobile-tab-action_activator-container_divider {
          content: "";
          position: absolute;
          background-color: rgba(255, 255, 255, 0.30);
          width: 0.5px;
          height: 22px;
        }
        .mobile-tab-action_activator {
            outline: none;
            background-color: inherit;
            border: none;
            border-radius: inherit;
            height: 100%;
            .mobile-tab-action_activator-container {
              .mobile-tab-action_icon, .active-filters-counter_icon.active-filters-counter_icon {
                width: rem(24);
                height: rem(24);
                path {
                  fill: $color-white;
                }
              }
            }

            &.mobile-tab-action_activator__active {
                .mobile-tab-action_activator-container {
                  .mobile-tab-action_icon, .active-filters-counter_icon {
                    path {
                      stroke: $color-white;
                    }
                  }
                  .mobile-tab-action_label, .active-filters-counter_label {
                    font-weight: 500;
                    color: $color-white;
                  }
                }
            }
            .mobile-tab-action_activator-container {
                display: flex;
                justify-content: center;
                align-items: center;
                height: inherit;
                .mobile-tab-action_label, .active-filters-counter_label {
                  font-family: $primary-font;
                  font-size: 14px;
                  font-weight: 400;
                  color: $color-white;
                  line-height: 150%;
                  letter-spacing: 0.01em;
                }
                .mobile-tab-action_label_filter {
                  margin-left: 3px;
                }
            }
        }
      }
    }

    &.search-results_tablet {
      overflow: hidden;
      .main_tablet {
        min-height: rem(360);
      }
      .map-container {
        .search-results-map {
          height: 100vh;
        }
        /*&.map-search__default, &.map-search__found {
          .map-control {
            &.map-viewtype, &.map-transport, &.map-pin {
              bottom: initial;
            }
          }
        }*/
        /*&.map-search__search {
          .search_container {
            left: 1.25rem;
          }
        }*/
      }
      .mobile-tab-action {
        width: rem(100);
        display: flex;
        justify-content: center;
        align-self: center;
        .mobile-tab-action_filter_activator-container {
          display: none;
          @media screen and (min-width: 601px) and (max-width: 789px) {
              display: block;
              padding-right: 10px;
          }
        }
        .mobile-tab-action_activator {
          width: 100%;
        }
        &.hide-button {
          display: none;
        }
      }
    }
    .map-info-container {
      bottom: unset;
      transform: translateX(-50%) translateY(0);
    }
    .map-info-container__active {
      .map-info-container {
        transform: translateX(-50%) translateY(300px) !important;
      }

      .map-expand-control {
        transform: translateY(-70px);
        // transition-delay: .1s;
      }
    }
    &.search-results_mobile {
      .map-container {
        /* &.listing-details--map-container:not(.map-container__fullscreenActive) {
          .map-zoom-controls, .map-control:not(.map-fullscreen) {
            display: none;
          }
        } ->LDP */
        .map-info-container {
          transform: translateX(-50%) translateY(70%);
        }

        &.map-info-container__active {
          .mobile-map-grid {
            .map-info-container.map-mobile {
              transform: translateX(0) translateY(-5%);
              height: 140px;
              opacity: 1;
              visibility: visible;
            }
          }
        }

        // .map-control {
        //   width: rem(40);
        //   height: rem(40);
        //   border-radius: rem(10);
        //   &.map-viewtype {
        //     top: rem(70);
        //   }
        //   &.map-transport {
        //     top: rem(120);
        //   }
        //   /* &.map-pin {
        //     margin-top: 0;
        //     top: 3%;
        //   } ->LDP */
        // }
      }
      .mobile-tab-action {
        transition: transform .4s cubic-bezier(0.2, 0.8, 0.4, 1);
      }
      &.search-results_mobile_filters {
        .mobile-tab-action {
          transform: translateY(300%) translateX(-50%) translateZ(0);
        }
      }
    }
    .header-mob{
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
}

.container-padding {
    padding: 66px 0 0;
}

.search-map {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #eee;
}

/*.property-card {
    display: grid;
    grid-template-columns: 30% 70%;
    img {
        max-width: 100%;
    }
} */
/*.container-padding {
    padding: 0;
}

.search-map {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #eee;
    .map-container {
      max-width: 100%;
    }
}*/

/* .property-card {
    display: grid;
    grid-template-columns: 30% 70%;
    img {
        max-width: 100%;
    }
} */

/*.map-style-3 {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: 100%;
  margin: 0;
  position: relative;
}
.map-style-3 iframe {
  width: 100%;
  height: 100%;
}
.map-style-3 .map-cross {
  width: auto;
  background: #FFFFFF;
  -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  padding: 0.525rem 0.812rem;
  border-radius: 0.375rem;
  position: absolute;
  left: 1.25rem;
  top: 1.25rem;
}
.map-style-3 .map-cross .cn_slct_box input:checked ~ span.label svg rect {
  fill: #4C35BC;
}
.map-style-3 .map-cross .cn_slct_box input:checked ~ span.label svg path {
  stroke: #4C35BC;
}
.map-style-3 .map-cross .cn_slct_box input:checked ~ span.label svg circle {
  fill: #4C35BC;
}
.map-style-3 .map-cross .cn_slct_box input:checked ~ span.label svg circle:last-child {
  fill: #FFFFFF;
}
.map-style-3 .map-cross .cn_slct_box:hover input ~ span.label svg rect {
  fill: #4C35BC;
}
.map-style-3 .map-cross .cn_slct_box:hover input ~ span.label svg path {
  stroke: #4C35BC;
}
.map-style-3 .map-cross .cn_slct_box:hover input ~ span.label svg circle {
  fill: #4C35BC;
}
.map-style-3 .map-cross .cn_slct_box:hover input ~ span.label svg circle:last-child {
  fill: #FFFFFF;
}
.map-style-3 .map-search {
  max-width: 21.375rem;
  width: 100%;
  background: #FFFFFF;
  -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  padding: 1.25rem 0.3125rem 0.3125rem 0.3125rem;
  border-radius: 0.375rem;
  position: absolute;
  left: 1.25rem;
  top: 6.5rem;
  z-index: 1;
}
@media screen  and (max-width: 600px) {
  .map-style-3 .map-search {
    box-shadow: none;
    bottom: 0;
    top: auto;
    left: 0;
    right: 0;
    max-width: 100%;
    border-radius: 0;
  }
}
.map-style-3 .map-search .map-search-bar {
  padding: 0 0.9375rem 1.25rem;
}
.map-style-3 .map-search h4 {
  text-align: center;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 500;
  color: #242526;
  padding: 0;
  margin: 0 0.9375rem 0.3125rem;
}
.map-style-3 .map-search p {
  text-align: left;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 300;
  color: #807D82;
  padding: 0 0.9375rem 1.875rem;
  margin: 0;
}
.map-style-3 .map-search ul.map-searh-list {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  display: inline-block;
  vertical-align: top;
}
@media screen  and (max-width: 600px) {
  .map-style-3 .map-search ul.map-searh-list {
    padding: 0 0.9375rem;
  }
}
.map-style-3 .map-search ul.map-searh-list li {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  padding: 0.625rem 0.9375rem;
  border-radius: 0.375rem;
  background: transparent;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 300;
  color: #242526;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
@media screen  and (max-width: 600px) {
  .map-style-3 .map-search ul.map-searh-list li {
    border-bottom: 1px solid #EBEAEB;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .map-style-3 .map-search ul.map-searh-list li:last-child {
    border-bottom: none;
  }
}
.map-style-3 .map-search ul.map-searh-list li span {
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 500;
}
@media screen and (min-width: 601px) {
  .map-style-3 .map-search ul.map-searh-list li:hover {
    background: #F2F1F2;
  }
}
.map-style-3 .map-area-checkbox {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0.9375rem 0 0;
}
.map-style-3 .map-area-checkbox:last-child {
  margin-right: 0;
}
.map-style-3 .map-area-checkbox .cn_slct_box {
  padding: 0;
  font-size: 0.75rem;
  line-height: 0.9375rem;
  font-weight: 300;
  color: #807D82;
}
.map-style-3 .map-area-checkbox .cn_slct_box .label svg {
  display: inline-block;
  vertical-align: middle;
}
.map-style-3 .map-area-checkbox .cn_slct_box .cn_checkmark {
  opacity: 0;
}
.map-style-3 .map-area-checkbox .cn_slct_box input:checked ~ .cn_checkmark {
  opacity: 0;
}
.map-style-3 .map-area-checkbox .cn_slct_box:hover input ~ .cn_checkmark {
  opacity: 0;
}
.map-style-3 .map-search-pin {
  width: auto;
  background: #FFFFFF;
  -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  padding: 0.4375rem 0.812rem;
  border-radius: 0.375rem;
  position: absolute;
  left: 1.25rem;
  top: 6.625rem;
}
@media screen  and (max-width: 600px) {
  .map-style-3 .map-search-pin {
    top: auto;
    bottom: 8rem;
  }
}
.map-style-3 .map-search-pin .pin-search-text {
  display: inline-block;
  vertical-align: middle;
  padding: 0 0 0 0.9375rem;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 300;
  color: #242526;
}
.map-style-3 .map-search-pin .pin-search-text a {
  display: inline-block;
  margin: 0 0 0 0.9375rem;
}
.map-style-3 .map-search-pin .cn_slct_box input:checked ~ span.label svg rect {
  fill: #4C35BC;
}
.map-style-3 .map-search-pin .cn_slct_box input:checked ~ span.label svg path {
  stroke: #4C35BC;
}
.map-style-3 .map-search-pin .cn_slct_box input:checked ~ span.label svg circle {
  fill: #4C35BC;
}
.map-style-3 .map-search-pin .cn_slct_box input:checked ~ span.label svg circle:last-child {
  fill: #FFFFFF;
}
.map-style-3 .map-search-pin .cn_slct_box:hover input ~ span.label svg rect {
  fill: #4C35BC;
}
.map-style-3 .map-search-pin .cn_slct_box:hover input ~ span.label svg path {
  stroke: #4C35BC;
}
.map-style-3 .map-search-pin .cn_slct_box:hover input ~ span.label svg circle {
  fill: #4C35BC;
}
.map-style-3 .map-search-pin .cn_slct_box:hover input ~ span.label svg circle:last-child {
  fill: #FFFFFF;
}
.map-style-3 .map-satellite {
  width: auto;
  background: #FFFFFF;
  -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  padding: 0.5rem 0.562rem;
  border-radius: 0.375rem;
  position: absolute;
  left: 1.25rem;
  top: 11.375rem;
}
@media screen  and (max-width: 600px) {
  .map-style-3 .map-satellite {
    top: auto;
    bottom: 4.625rem;
  }
}
.map-style-3 .map-satellite .cn_slct_box input:checked ~ span.label svg {
  fill: #4C35BC;
}
.map-style-3 .map-satellite .cn_slct_box input:checked ~ span.label svg path {
  stroke: #FFFFFF;
}
.map-style-3 .map-satellite .cn_slct_box input:checked ~ span.label svg circle {
  stroke: #FFFFFF;
}
.map-style-3 .map-satellite .cn_slct_box:hover input ~ span.label svg {
  fill: #4C35BC;
}
.map-style-3 .map-satellite .cn_slct_box:hover input ~ span.label svg path {
  stroke: #FFFFFF;
}
.map-style-3 .map-satellite .cn_slct_box:hover input ~ span.label svg circle {
  stroke: #FFFFFF;
}
.map-style-3 .map-commute {
  width: auto;
  background: #FFFFFF;
  -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  padding: 0.375rem 0.594rem;
  border-radius: 0.375rem;
  position: absolute;
  left: 1.25rem;
  top: 13.9375rem;
}
@media screen  and (max-width: 600px) {
  .map-style-3 .map-commute {
    top: auto;
    bottom: 1.25rem;
  }
}
.map-style-3 .map-commute .cn_slct_box input:checked ~ span.label svg path {
  stroke: #4C35BC;
}
.map-style-3 .map-commute .cn_slct_box input:checked ~ span.label svg circle {
  fill: #FFFFFF;
}
.map-style-3 .map-commute .cn_slct_box input:checked ~ span.label svg rect {
  stroke: #FFFFFF;
}
.map-style-3 .map-commute .cn_slct_box:hover input ~ span.label svg path {
  stroke: #4C35BC;
}
.map-style-3 .map-commute .cn_slct_box:hover input ~ span.label svg circle {
  fill: #4C35BC;
}
.map-style-3 .map-commute .cn_slct_box:hover input ~ span.label svg rect {
  stroke: #4C35BC;
}
.map-style-3 .map-expand, .map-style-3 .map-collapse {
  width: auto;
  background: #FFFFFF;
  -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  padding: 0;
  border-radius: 0.375rem;
  position: absolute;
  right: 1.25rem;
  top: 1.25rem;
}
@media screen  and (max-width: 1136px) {
  .map-style-3 .map-expand, .map-style-3 .map-collapse {
    display: none;
  }
}
.map-style-3 .map-expand .cn_slct_box input:checked ~ span.label svg path, .map-style-3 .map-collapse .cn_slct_box input:checked ~ span.label svg path {
  stroke: #4C35BC;
}
.map-style-3 .map-expand .cn_slct_box input:checked ~ span.label svg circle, .map-style-3 .map-collapse .cn_slct_box input:checked ~ span.label svg circle {
  fill: #FFFFFF;
}
.map-style-3 .map-expand .cn_slct_box input:checked ~ span.label svg rect, .map-style-3 .map-collapse .cn_slct_box input:checked ~ span.label svg rect {
  stroke: #FFFFFF;
}
.map-style-3 .map-expand .cn_slct_box:hover input ~ span.label svg path, .map-style-3 .map-collapse .cn_slct_box:hover input ~ span.label svg path {
  stroke: #4C35BC;
}
.map-style-3 .map-expand .cn_slct_box:hover input ~ span.label svg circle, .map-style-3 .map-collapse .cn_slct_box:hover input ~ span.label svg circle {
  fill: #4C35BC;
}
.map-style-3 .map-expand .cn_slct_box:hover input ~ span.label svg rect, .map-style-3 .map-collapse .cn_slct_box:hover input ~ span.label svg rect {
  stroke: #4C35BC;
}
.map-style-3 .map-recommendation {
  width: auto;
  background: #FFFFFF;
  -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  padding: 0.4375rem 0.9375rem;
  border-radius: 0.375rem;
  position: absolute;
  left: 1.25rem;
  bottom: 1.25rem;
}
.map-style-3 .map-recommendation .cn_slct_box input:checked ~ span.label svg path {
  fill: #4C35BC;
  stroke: #4C35BC;
}
.map-style-3 .map-recommendation .cn_slct_box:hover input ~ span.label svg path {
  fill: #4C35BC;
  stroke: #4C35BC;
}
.map-style-3 .map-counts {
  background: #242526;
  border: 0.125rem solid #FFFFFF;
  border-radius: 50%;
  height: 1.875rem;
  width: 1.875rem;
  font-family: $primary-font;
  font-size: 0.75rem;
  line-height: 1.7rem;
  font-weight: 500;
  color: #FFFFFF;
  letter-spacing: 0.05em;
  cursor: pointer;
  padding: 0;
  margin: 0;
  text-align: center;
}
.map-style-3 .map-radius {
  background: transparent;
  border: 0.0625rem solid #F35597;
  border-radius: 50%;
  padding: 0;
  margin: 0;
}
.map-style-3 .map-price {
  background: #574B90;
  border: 0.0625rem solid #FFFFFF;
  border-radius: 0.5rem;
  height: 1.5rem;
  font-family: $primary-font;
  font-size: 0.75rem;
  line-height: 1.38rem;
  font-weight: 500;
  color: #FFFFFF;
  letter-spacing: 0.05em;
  cursor: pointer;
  padding: 0 0.625rem;
  margin: 0;
  text-align: center;
}
.map-style-3 .map-price.pink-bg {
  background: #F35597;
  color: #FFFFFF;
}
.map-style-3 .map-price svg {
  float: left;
  margin-right: 0.3125rem;
  margin-top: 0.32rem;
}
.map-style-3 .map-tooltip {
  background: #242526;
  border: none;
  border-radius: 0.375rem;
  max-width: 9.5625rem;
  padding: 0.625rem 0.9375rem;
  font-size: 0.75rem;
  line-height: 0.9375rem;
  font-weight: 300;
  color: #B3B1B4;
  margin: 0;
}
.map-style-3 .map-tooltip:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -0.25rem;
  border-width: 0.25rem;
  border-style: solid;
  border-color: #242526 transparent transparent transparent;
}
.map-style-3 .map-tooltip span.white {
  color: #FFFFFF;
}
.map-style-3 .map-tooltip span.map-tootip-price {
  font-weight: 500;
  color: #FFFFFF;
}
.map-style-3 .property-tooltip {
  background: #FFFFFF;
  border: none;
  border-radius: 0.375rem;
  -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  width: 20.125rem;
  height: 5.9375rem;
  padding: 0;
  margin: 0;
}
@media screen  and (max-width: 1136px) {
  .map-style-3 .property-tooltip {
    margin: auto;
    bottom: 3.125rem !important;
    top: auto !important;
    left: 0 !important;
    right: 0 !important;
  }
}
.map-style-3 .property-tooltip:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 5.85rem;
  border-width: 0.4375rem;
  border-style: solid;
  border-color: #FFFFFF transparent transparent transparent;
}
@media screen  and (max-width: 1136px) {
  .map-style-3 .property-tooltip:after {
    display: none;
  }
}
.map-style-3 .property-tooltip .property-left {
  float: left;
  width: 5.625rem;
  height: 5.9375rem;
  border-radius: 0.375rem 0 0 0.375rem;
  overflow: hidden;
}
.map-style-3 .property-tooltip .property-left img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.map-style-3 .property-tooltip .property-right {
  float: left;
  width: calc(100% - 90px);
  padding: 0.625rem 0.625rem 1.875rem 0.625rem;
  margin: 0;
  position: relative;
  height: 5.9375rem;
}
.map-style-3 .property-tooltip .property-right .property-top {
  display: inline-block;
  vertical-align: top;
  width: 100%;
}
.map-style-3 .property-tooltip .property-right .property-top h4 {
  float: left;
  max-width: 11.125rem;
  font-family: $primary-font;
  font-size: 0.875rem;
  line-height: 1.125rem;
  font-weight: normal;
  color: #242526;
  padding: 0;
  margin: 0;
}
.map-style-3 .property-tooltip .property-right .property-top h4 a {
  font-family: $primary-font;
  font-size: 0.875rem;
  line-height: 1.125rem;
  font-weight: normal;
  color: #242526;
}
.map-style-3 .property-tooltip .property-right .property-top svg {
  float: right;
  margin: 0.3125rem 0.3125rem 0 0;
  cursor: pointer;
}
.map-style-3 .property-tooltip .property-right .property-top svg.selected {
  fill: #242526;
}
.map-style-3 .property-tooltip .property-right .property-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 0.625rem 0.625rem;
  width: 100%;
  display: inline-block;
  vertical-align: top;
}
.map-style-3 .property-tooltip .property-right .property-bottom .property-area {
  float: left;
  font-size: 0.875rem;
  line-height: 1.125rem;
  font-weight: normal;
  color: #807D82;
  max-width: 78%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.map-style-3 .property-tooltip .property-right .property-bottom .property-rating {
  float: right;
}
.map-style-3 .property-tooltip .property-right .property-bottom .property-rating svg {
  float: left;
  margin-top: 0.125rem;
}
.map-style-3 .property-tooltip .property-right .property-bottom .property-rating .rating-value {
  float: right;
  font-size: 1rem;
  line-height: 1.1875rem;
  font-weight: normal;
  color: #242526;
  margin: 0 0 0 0.3125rem;
}

.cn-tabs {
  width: 100%;
}
.cn-tabs .cn-tabs-nav {
  margin: 0px;
  padding: 0px;
  list-style: none;
}
.cn-tabs .cn-tabs-nav li {
  background: none;
  color: #242526;
  display: inline-block;
  padding: 0.625rem 0.938rem;
  cursor: pointer;
}
.cn-tabs .cn-tabs-nav li.cn-active {
  background: #EBEAEB;
  color: #242526;
}

.cn-tabs-content {
  display: none;
  background: #EBEAEB;
  padding: 0.938rem;
}
.cn-tabs-content.cn-active {
  display: inherit;
}

.cn-tabs .cn-tabs-nav {
  margin-bottom: 2.5625rem;
  margin-top: 1.5625rem;
}
.cn-tabs .cn-tabs-nav.tab-center {
  text-align: center;
}
.cn-tabs .cn-tabs-nav li {
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 300;
  color: #242526;
  padding-bottom: 0;
  padding-top: 0;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  height: 1.25rem;
}
.cn-tabs .cn-tabs-nav li span {
  display: inline-block;
  vertical-align: top;
  padding-bottom: 0.75rem;
  border-bottom: 0.1875rem solid transparent;
}
.cn-tabs .cn-tabs-nav li:first-child {
  padding-left: 0;
}
.cn-tabs .cn-tabs-nav li:last-child {
  padding-right: 0;
  border-right: none;
}
.cn-tabs .cn-tabs-nav li.cn-active {
  background: transparent;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 500;
}
.cn-tabs .cn-tabs-nav li.cn-active span {
  border-bottom: 0.1875rem solid #F35597;
}
.cn-tabs .cn-tabs-content {
  padding: 0;
  background: transparent;
}
.cn-tabs .cn-tabs-content .cn-input-group,
.cn-tabs .cn-tabs-content .calendar-input {
  margin-bottom: 1.25rem;
}
@media screen and (min-width: 601px) and (max-width: 1136px) {
  .cn-tabs .cn-tabs-content .cn-input-group,
.cn-tabs .cn-tabs-content .calendar-input {
    margin-bottom: 1.5625rem;
  }
}
.cn-tabs .cn-tabs-content .guests-input-box {
  margin-bottom: 2.5rem;
}
.cn-tabs .cn-tabs-content .btn-primary {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.search-bar {
  display: inline-block;
  vertical-align: top;
  width: 100%;
}
.search-bar .search-input {
  float: left;
  width: 43.17%;
  margin-right: 1.25rem;
}
.search-bar .calendar-input {
  background: #F8F7F7;
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  float: left;
  width: 24.6%;
  margin-right: 1.25rem;
  margin-bottom: 0 !important;
}
.search-bar .calendar-input input {
  background: #F8F7F7;
  padding-right: 8px;
  padding-left: 8px;
  text-align: center;
}
.search-bar .guests-input-box {
  float: left;
  width: 15.8%;
  margin-bottom: 0 !important;
}
.search-bar .guests-input-box .guests-input {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border: none;
  background: #F8F7F7;
  column-gap: 0;
}
.search-bar .guests-input-box .guests-input input {
  border: none;
  background: #F8F7F7;
  width: 100%;
  margin: 0;
  padding: 0 0 0 10px;
}
.search-bar .guests-input-box .guests-dd-box {
  width: 18rem;
  left: auto;
  right: 0;
}

.filter-bar {
  display: inline-block;
  vertical-align: top;
  width: 100%;
}
.filter-bar .dropdown-box {
  float: left;
  margin-bottom: 0 !important;
}
.filter-bar .dropdown-box.trip-type {
  width: 19.4%;
  margin-right: 1.25rem;
}
.filter-bar .dropdown-box.currency-range {
  width: 13.2%;
  margin-right: 1.25rem;
}
.filter-bar .dropdown-box.rating-type {
  width: 16.7%;
  margin-right: 1.25rem;
}
.filter-bar .dropdown-box.filter-type {
  width: 17%;
}
.filter-bar .dropdown-box .dropdown-input {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border: none;
  background: #F8F7F7;
  column-gap: 0;
}
.filter-bar .dropdown-box .dropdown-input input {
  border: none;
  background: #F8F7F7;
  width: 100%;
  margin: 0;
  padding: 0 0 0 10px;
}
.filter-bar .dropdown-box.pop-open .dropdown-input {
  background: #EBEAEB;
}
.filter-bar .dropdown-box.pop-open .dropdown-input input {
  background: #EBEAEB;
}
.filter-bar .dropdown-box .drop-down-box {
  width: 21.8125rem;
}
.filter-bar .filters-selected {
  padding: 1.5625rem 0 0 0;
}

.sort-bar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
.sort-bar .sort-text {
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 300;
  color: #797882;
  margin: 0 0.625rem 0 0;
}
.sort-bar .dropdown-box {
  float: left;
  margin-bottom: 0 !important;
  width: 10rem;
}
.sort-bar .dropdown-box ul {
  padding: 0.625rem;
}
.sort-bar .dropdown-box ul li {
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 300;
  color: #242526;
  margin: 0;
  border-radius: 6px;
  padding: 0.625rem;
}
.sort-bar .dropdown-box ul li:hover {
  background: #EBEAEB;
}
.sort-bar .dropdown-box ul li.selected {
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 500;
}
.sort-bar .dropdown-box .dropdown-input {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border: none;
  background: #F8F7F7;
  column-gap: 0;
}
.sort-bar .dropdown-box .dropdown-input input {
  border: none;
  background: #F8F7F7;
  width: 100%;
  margin: 0;
  padding: 0 0 0 10px;
}
.sort-bar .dropdown-box.pop-open .dropdown-input {
  background: #EBEAEB;
}
.sort-bar .dropdown-box.pop-open .dropdown-input input {
  background: #EBEAEB;
}
.sort-bar .dropdown-box .drop-down-box {
  width: 12.1875rem;
}

.tile {
  background: #FFFFFF;
  border: none;
  border-radius: 0.625rem;
  width: 100%;
  height: 10.625rem;
  padding: 0;
  margin: 0;
  cursor: default;
}
.tile .tile-left {
  float: left;
  width: 15.625rem;
  height: 10.625rem;
  border-radius: 0.625rem 0 0 0.625rem;
  overflow: hidden;
  position: relative;
}
.tile .tile-left img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.tile .tile-left .wishlist-icon {
  width: 1.5rem;
  height: 1.5rem;
  background: #FCFCFC;
  border-radius: 50%;
  position: absolute;
  left: 0.625rem;
  top: 0.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tile .tile-left .wishlist-icon svg {
  cursor: pointer;
}
.tile .tile-left .wishlist-icon svg.selected path {
  fill: #F35597;
  stroke: #F35597;
}
.tile .tile-left .wishlist-icon svg.blacked path {
  fill: #242526;
  stroke: #242526;
}
.tile .tile-right {
  float: left;
  width: calc(100% - 250px);
  height: 10.625rem;
  padding: 0.625rem 1.25rem 2rem 1.25rem;
  margin: 0;
  position: relative;
}
.tile .tile-right .tile-top {
  display: inline-block;
  vertical-align: top;
  width: 100%;
}
.tile .tile-right .tile-top .tile-type {
  font-size: 1rem;
  line-height: 1.1875rem;
  font-weight: 300;
  font-variant: small-caps;
  text-transform: lowercase;
  color: #797882;
  font-weight: 500;
  padding: 0;
  margin: 0 0 0.4375rem 0;
}
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .tile .tile-right .tile-top .tile-type {
      font-size: 1.25rem;
    }
  }
}
.tile .tile-right .tile-top .tile-type .stars {
  display: inline-block;
  vertical-align: top;
  padding: 0.1875rem 0 0 0.375rem;
}
.tile .tile-right .tile-top .tile-type .stars svg {
  float: left;
  fill: #B3B1B4;
}
.tile .tile-right .tile-top h2 {
  font-family: $primary-font;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 300;
  color: #242526;
  margin: 0 0 0.625rem 0;
  padding: 0;
}
.tile .tile-right .tile-top h2 a {
  font-family: $primary-font;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 300;
  color: #242526;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.tile .tile-right .tile-top .tile-area {
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 300;
  color: #797882;
  margin: 0 0 0.625rem 0;
}
.tile .tile-right .tile-top .tile-amenities {
  display: inline-block;
  vertical-align: top;
  width: 100%;
}
.tile .tile-right .tile-top .tile-amenities .amenities-item {
  float: left;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 300;
  color: #797882;
  padding: 0 0.625rem;
  border-right: 1px solid #EBEAEB;
}
.tile .tile-right .tile-top .tile-amenities .amenities-item:first-child {
  padding-left: 0;
}
.tile .tile-right .tile-top .tile-amenities .amenities-item:last-child {
  padding-right: 0;
  border: none;
}
.tile .tile-right .tile-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 1.25rem 0.625rem 1.25rem;
  width: 100%;
  display: inline-block;
  vertical-align: top;
}
.tile .tile-right .tile-bottom .tile-rating {
  float: left;
}
.tile .tile-right .tile-bottom .tile-rating svg {
  float: left;
  margin-top: 0.125rem;
}
.tile .tile-right .tile-bottom .tile-rating svg.selected path {
  fill: #F35597;
}
.tile .tile-right .tile-bottom .tile-rating .rating-value {
  float: right;
  font-size: 1rem;
  line-height: 1.1875rem;
  font-weight: normal;
  color: #242526;
  margin: 0 0 0 0.3125rem;
}
.tile .tile-right .tile-bottom .tile-price {
  float: right;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 500;
  color: #242526;
}
.tile .tile-right .tile-bottom .tile-price span {
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 300;
  color: #242526;
}
.tile:hover {
  -webkit-box-shadow: 0px 2px 20px rgba(87, 75, 144, 0.2);
  -moz-box-shadow: 0px 2px 20px rgba(87, 75, 144, 0.2);
  box-shadow: 0px 2px 20px rgba(87, 75, 144, 0.2);
}
.tile:hover .tile-right .tile-top .tile-type {
  color: #242526;
}
.tile:hover .tile-right .tile-top .tile-type .stars svg {
  fill: #807D82;
}
.tile.selected {
  -webkit-box-shadow: 0px 2px 20px rgba(87, 75, 144, 0.2);
  -moz-box-shadow: 0px 2px 20px rgba(87, 75, 144, 0.2);
  box-shadow: 0px 2px 20px rgba(87, 75, 144, 0.2);
}
.tile.selected .tile-right .tile-top .tile-type {
  color: #242526;
}
.tile.selected .tile-right .tile-top .tile-type .stars svg {
  fill: #807D82;
}

.filters-selected {
  display: inline-block;
  width: 100%;
  vertical-align: top;
}
.filters-selected .filter-item {
  font-size: 0.75rem;
  line-height: 0.9375rem;
  font-weight: 300;
  color: #797882;
  border: 1px solid #B3B1B4;
  border-radius: 1rem;
  padding: 0.4375rem 0.625rem;
  margin: 0 0.625rem 0.625rem 0;
  float: left;
  cursor: default;
}
.filters-selected .filter-item a {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 0 0.625rem;
}
.filters-selected .filter-item a img {
  display: block;
}

.map-search-results {
  grid-column: 1/9;
  height: 100vh;
  overflow: hidden;
}
@media screen and (min-width: 601px) and (max-width: 1136px) {
  .map-search-results {
    display: none;
  }
}

.search-right {
  grid-column: 9/18;
  height: 100vh;
  overflow: auto;
  background: #F8F7F7;
  -webkit-box-shadow: 0px 2px 20px rgba(87, 75, 144, 0.2);
  -moz-box-shadow: 0px 2px 20px rgba(87, 75, 144, 0.2);
  box-shadow: 0px 2px 20px rgba(87, 75, 144, 0.2);
}
@media screen and (min-width: 601px) and (max-width: 1136px) {
  .search-right {
    grid-column: 1/18;
  }
}
.search-right .tile {
  margin-bottom: 1.5625rem;
}

.search-bar {
  padding: 0 3.125rem 1.25rem 1.25rem;
}
@media screen and (min-width: 601px) and (max-width: 1136px) {
  .search-bar {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
}

.filter-bar {
  padding: 0 3.125rem 1.25rem 1.25rem;
  border-bottom: 1px solid #EBEAEB;
}
@media screen and (min-width: 601px) and (max-width: 1136px) {
  .filter-bar {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
}

.sort-bar {
  padding: 0.9375rem 3.125rem 1.5625rem 1.25rem;
}
@media screen and (min-width: 601px) and (max-width: 1136px) {
  .sort-bar {
    display: none;
  }
}

.search-tiles {
  padding: 0 3.125rem 1.5625rem 1.25rem;
  display: inline-block;
  vertical-align: top;
  width: 100%;
}
@media screen and (min-width: 601px) and (max-width: 1136px) {
  .search-tiles {
    padding-top: 2.5rem;
    padding-bottom: 7.5rem;
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
}

.logo-only {
  display: none;
}

.filters-only {
  display: none;
}

.services-type {
  display: none;
}

.amenities-type {
  display: none;
}

.policy-type {
  display: none;
}

.search-without-map {
  display: none;
}

.map-bottom-devices {
  display: none;
}
@media screen and (min-width: 601px) and (max-width: 1136px) {
  .map-bottom-devices {
    display: block;
    display: block;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 7.5625rem;
    text-align: center;
    z-index: 9;
    padding: 2.1875rem 1.875rem 1.5rem 1.875rem;
    background: #FFFFFF;
    -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 500;
    color: #242526;
    cursor: pointer;
  }
  .map-bottom-devices img {
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.4375rem;
  }
}

.map-expanded .map-search-results {
  grid-column: 5/18;
  grid-row: 1;
}
@media screen and (min-width: 601px) and (max-width: 1136px) {
  .map-expanded .map-search-results {
    display: block;
    grid-column: 1/18;
  }
}
.map-expanded .search-right {
  grid-column: 1/5;
  grid-row: 1;
}
@media screen and (min-width: 601px) and (max-width: 1136px) {
  .map-expanded .search-right {
    display: none;
  }
}
.map-expanded .search-right .header {
  display: none;
}
.map-expanded .search-right .sort-bar {
  display: none;
}
.map-expanded .search-right .search-tiles {
  display: none;
}
.map-expanded .search-right .filter-type {
  display: none;
}
.map-expanded .search-right .filters-selected {
  display: none;
}
.map-expanded .search-right .services-type {
  display: inline-block;
}
.map-expanded .search-right .amenities-type {
  display: inline-block;
}
.map-expanded .search-right .policy-type {
  display: inline-block;
}
.map-expanded .search-right .logo-only {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  margin: 0;
  padding: 0.9375rem 0 1.25rem 1.25rem;
}
.map-expanded .search-right .search-bar {
  padding: 0 1.25rem 1.25rem 1.25rem;
  border-bottom: 1px solid #EBEAEB;
}
.map-expanded .search-right .search-bar .search-input {
  width: 100%;
  margin: 0 0 1.25rem 0;
}
.map-expanded .search-right .search-bar .calendar-input {
  width: 51%;
}
.map-expanded .search-right .search-bar .guests-input-box {
  width: 32.7%;
}
.map-expanded .search-right .filters-only {
  display: inline-block;
  vertical-align: top;
  width: 100%;
}
.map-expanded .search-right .filters-only .filters-selected {
  display: inline-block;
  padding: 1.25rem 1.25rem 3.125rem 1.25rem;
}
.map-expanded .search-right .filter-bar {
  padding: 0 0.625rem 1.8rem;
  border: none;
}
.map-expanded .search-right .filter-bar .dropdown-box {
  border-bottom: 1px solid #EBEAEB;
  width: 100%;
  margin: 0;
  padding: 10px 0;
}
.map-expanded .search-right .filter-bar .dropdown-box .drop-down-box {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-radius: 0 0 0.375rem 0.375rem;
  position: relative;
  top: 0;
  width: 100%;
}
.map-expanded .search-right .filter-bar .dropdown-box .drop-down-box .drop-down-btns {
  display: none;
}
.map-expanded .search-right .filter-bar .dropdown-box.pop-open .dropdown-input {
  border-radius: 0.375rem 0.375rem 0 0;
  background: #FFFFFF;
}
.map-expanded .search-right .filter-bar .dropdown-box.pop-open .dropdown-input input {
  background: #FFFFFF;
}

@media screen and (min-width: 601px) and (max-width: 1136px) {
  .map-minimized {
    display: none;
  }
}
.map-minimized .map-search-results {
  display: none;
}
.map-minimized .search-right {
  grid-column: 1/5;
  grid-row: 1;
  padding-top: 4.23rem;
  border-right: 1px solid #EBEAEB;
}
.map-minimized .search-right .header {
  display: none;
}
.map-minimized .search-right .sort-bar {
  display: none;
}
.map-minimized .search-right .search-tiles {
  display: none;
}
.map-minimized .search-right .filter-type {
  display: none;
}
.map-minimized .search-right .filters-selected {
  display: none;
}
.map-minimized .search-right .services-type {
  display: inline-block;
}
.map-minimized .search-right .amenities-type {
  display: inline-block;
}
.map-minimized .search-right .policy-type {
  display: inline-block;
}
.map-minimized .search-right .search-bar {
  padding: 0 1.25rem 1.25rem 1.25rem;
  border-bottom: 1px solid #EBEAEB;
}
.map-minimized .search-right .search-bar .search-input {
  width: 100%;
  margin: 0 0 1.25rem 0;
}
.map-minimized .search-right .search-bar .calendar-input {
  width: 51%;
}
.map-minimized .search-right .search-bar .guests-input-box {
  width: 32.7%;
}
.map-minimized .search-right .filters-only {
  display: inline-block;
  vertical-align: top;
  width: 100%;
}
.map-minimized .search-right .filters-only .filters-selected {
  display: inline-block;
  padding: 1.25rem 1.25rem 3.125rem 1.25rem;
}
.map-minimized .search-right .filter-bar {
  padding: 0 0.625rem 1.8rem;
  border: none;
}
.map-minimized .search-right .filter-bar .dropdown-box {
  border-bottom: 1px solid #EBEAEB;
  width: 100%;
  margin: 0;
  padding: 10px 0;
}
.map-minimized .search-right .filter-bar .dropdown-box .drop-down-box {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-radius: 0 0 0.375rem 0.375rem;
  position: relative;
  top: 0;
  width: 100%;
}
.map-minimized .search-right .filter-bar .dropdown-box .drop-down-box .drop-down-btns {
  display: none;
}
.map-minimized .search-right .filter-bar .dropdown-box.pop-open .dropdown-input {
  border-radius: 0.375rem 0.375rem 0 0;
  background: #FFFFFF;
}
.map-minimized .search-right .filter-bar .dropdown-box.pop-open .dropdown-input input {
  background: #FFFFFF;
}
.map-minimized .search-without-map {
  background: #FCFCFC;
  display: inherit;
  grid-column: 5/18;
  grid-row: 1;
}
.map-minimized .search-without-map .header.header-search-results {
  border-bottom: none;
  grid-column: initial;
  display: inline-block;
  width: 100%;
  vertical-align: top;
}
.map-minimized .search-without-map .without-map {
  display: inline-block;
  vertical-align: top;
  width: 100%;
}
.map-minimized .search-without-map .without-map .without-map-left {
  float: left;
  width: 60.25%;
}
.map-minimized .search-without-map .without-map .without-map-left .without-map-top {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  padding: 0 1.25rem 1.25rem 2.5rem;
}
.map-minimized .search-without-map .without-map .without-map-left .without-map-top .without-map-first {
  float: left;
  width: 120px;
}
.map-minimized .search-without-map .without-map .without-map-left .without-map-top .without-map-first .show-map {
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 300;
  color: #4C35BC;
  display: flex;
  justify-content: left;
  align-items: center;
  cursor: pointer;
  padding: 0.4375rem 0;
}
.map-minimized .search-without-map .without-map .without-map-left .without-map-top .without-map-first .show-map svg {
  margin: 0 0.625rem 0 0;
}
.map-minimized .search-without-map .without-map .without-map-left .without-map-top .without-map-last {
  float: right;
  width: 50%;
}
.map-minimized .search-without-map .without-map .without-map-left .without-map-top .without-map-last .sort-bar {
  padding: 0;
}
.map-minimized .search-without-map .without-map .without-map-left .search-tiles {
  padding: 0 0 1.25rem 1.25rem;
}
.map-minimized .search-without-map .without-map .without-map-left .search-tiles .tile {
  margin-bottom: 1.5625rem;
}
.map-minimized .search-without-map .without-map .without-map-right {
  float: left;
  width: 39.75%;
}*/
